import { ReactNode } from 'react';

type StatsCardProps = {
  title: string;
  value: number;
  description: string;
  icon: ReactNode;
};

export const StatsCard = ({ title, value, description, icon }: StatsCardProps) => (
  <div className="bg-white rounded-xl border border-gray-200 p-6 shadow-sm hover:shadow-md transition-shadow duration-200">
    <div className="flex items-center">
      <div className="p-2 bg-blue-50 rounded-lg">
        {icon}
      </div>
      <div className="ml-4">
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        <div className="mt-1 flex items-baseline">
          <p className="text-2xl font-semibold text-gray-900">{value.toLocaleString()}</p>
        </div>
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      </div>
    </div>
  </div>
);