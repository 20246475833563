/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import {useAuth, getUserData, isAdmin, isSuperAdmin} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import {Modal} from 'react-bootstrap';
import GuidelineModal from '../../../../app/pages/utils/GuidelineModal'
import GuidelineViewOnlyModal from '../../../../app/pages/utils/GuidelineViewOnlyModal'
import NPSModal from '../../../../app/pages/utils/NPSModal'
import * as API from '../../../../app/api';
import styled from '@emotion/styled';
import NPSSurvey from '../../../../app/pages/nps/NPSSurvey';

const StyledData = styled.div`
	.menu {
		z-index: 99999;
	}
`;

const HeaderUserMenu: FC = () => {
	const {currentUser, logout} = useAuth()
	const [modalGuidelines, showModalGuidelines] = useState<boolean>(false);
	const [modalGuidelinesViewOnly, showModalGuidelinesViewOnly] = useState<boolean>(false);
	const [modalNPS, showModalNPS] = useState<boolean>(false);
	const [guidelines, setGuidelines] = useState<any>(null);
	const [features, setFeatures] = useState<any[]|undefined>(undefined);
	const [idSurvey, setIdSurvey] = useState<any>(null);
	const [survey, setSurvey] = useState<any>(null);


	const getNPS = async() => {
        const response = await API.isSurveyAvailable();
		if (response.success && response.data.id_survey) {
			setSurvey(response.data);
			setIdSurvey(response.data.id_survey);
			showModalNPS(true);
		}
    }

	const getGuidelines = async() => {
		const response = await API.getGuidelines();
		if (!response.data) return;
		const userData = getUserData();
		setGuidelines(response.data);
	}

	const getFeatures = async() => {
		const result = await API.getCompanyFeatures();
		setFeatures(result.data);
	}

    useEffect(() => {
        getNPS();
		getGuidelines();
		getFeatures();
    }, []);

	const onLogoutClick = ():any => {
		onMenuClick('Sair');
		logout();
	}

	const onMenuClick = (title:string):any => {
		window.gtag('event', 'click', {
			'event_category': 'Header',
			'event_label': title
		});
	}

	return (
    <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px' data-kt-menu='true'>
		<Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-1000px' show={modalGuidelines}>
			<GuidelineModal onClose={() => { showModalGuidelines(false) }}></GuidelineModal>
		</Modal>
		<Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-1000px' show={modalGuidelinesViewOnly}>
			<GuidelineViewOnlyModal path={guidelines?.src} onClose={() => { showModalGuidelinesViewOnly(false) }}></GuidelineViewOnlyModal>
		</Modal>
		<Modal id='modal_nps' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-1000px' show={modalNPS}>
			<NPSSurvey survey={survey} onClose={() => { showModalNPS(false) }}></NPSSurvey>
		</Modal>
		<div className='menu-item px-3'>
			<div className='menu-content d-flex align-items-center px-3'>
				<div className='symbol symbol-50px me-5'>
					<img alt='Logo' src={getUserData().src ? getUserData().src : toAbsoluteUrl('/media/icons/duotune/general/gen049.svg')} />
				</div>
			<div className='d-flex flex-column'>
				<div className='fw-bolder d-flex align-items-center fs-5'>
					{getUserData()?.name}
					<span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{isSuperAdmin() ? 'Super Admin' : isAdmin() ? 'Admin' : 'Usuário'}</span>
				</div>
				<a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
					{getUserData()?.email}
				</a>
			</div>
		</div>
	</div>
	<div className='separator my-2'></div>
		<div className='menu-item px-5'>
			<Link to={'/profile'} className='menu-link px-5' onClick={onMenuClick('Meu Perfil')}>Meu Perfil</Link>
		</div>
		{ features?.some(feature => feature.name === 'support') ? 
		<div className='menu-item px-5'>
			<Link to={'/support'} className='menu-link px-5' onClick={onMenuClick('Suporte')}>
				<span className='menu-text'>Suporte</span>
			</Link>
		</div> : null }
		
		{ getUserData()?.guidelines ?
		<div className='menu-item px-5'>
			<div className='menu-link px-5' onClick={ () => {
				onMenuClick('Termos de Uso');
				showModalGuidelinesViewOnly(true);
			}}>
				<span className='menu-text'>Termos de uso</span>
			</div>
		</div> : null }
		{ isSuperAdmin() ? 
		<>
			<div className='menu-item px-5' data-kt-menu-trigger='hover' data-kt-menu-placement='left-start' data-kt-menu-flip='bottom'>
				<a href='#' className='menu-link px-5'>
					<span className='menu-title'>Administrativo</span>
					<span className='menu-arrow'></span>
				</a>
				<div className='menu-sub menu-sub-dropdown w-175px py-4'>
					<div className='menu-item px-3'>
						<Link to={'/users'} className='menu-link px-5' onClick={onMenuClick('Usuários')}>Usuários</Link>
					</div>
					{ isSuperAdmin() ? <>
						<div className='menu-item px-3'>
							<Link to={'/generalmenu'} className='menu-link px-5' onClick={onMenuClick('Menu')}>Menu</Link>
						</div>
						<div className='menu-item px-3'>
							<Link to={'/companys'} className='menu-link px-5' onClick={onMenuClick('Empresas')}>Empresas</Link>
						</div>
						
						<div className='menu-item px-3'>
							<Link to={'/nps'} className='menu-link px-5' onClick={onMenuClick('NPS')}>NPS</Link>
						</div>
						
						
						<div className='menu-item px-3'>
							<a href='#' className='menu-link px-5' onClick={() => showModalGuidelines(true)}>Termos de Uso</a>
						</div>
						
					</> : <></> }
					
				</div>
			</div>
		</> : isAdmin() ?  
		<div className='menu-item px-5'>
			<Link to={'/users'} className='menu-link px-5' onClick={onMenuClick('Usuários')}>Usuários</Link>
		</div> : <></> }

      	<div className='separator my-2'></div>

		<div className='menu-item px-5'>
			<a onClick={onLogoutClick} className='menu-link px-5'>Sair</a>
		</div>
    </div>
  	)
}

export {HeaderUserMenu}
