import { PageTitle } from '../../../_metronic/layout/core'
import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as API from '../../api';
import PermissionsModal from '../generalmenu/PermissionsModal';
import {Modal} from 'react-bootstrap';
import CompanyPermissions from './CompanyPermissions';

const StyledData = styled.div`
    .remove-user-title {
        margin:20px !important;
    }

    .table {
        margin:20px;
    }

    .card:hover {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    }

    .table-row-dashed tbody tr {
        transition: background-color 0.15s ease-out;
    }

    .table-row-dashed tbody tr:hover {
        background-color: #f6f6f6;
        
    }

    .accordion {
        width: 100%;
        margin: 20px auto;
    }

    .accordion-item {
        border-bottom: 1px dashed #ccc;
        margin-bottom: 10px;
    }

    .accordion-title {
        padding: 10px;
        cursor: pointer;
        background-color: #f9f9f9;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .accordion-title:hover {
        background-color: #ebebeb;
    }

    .accordion-title.active {
        background-color: #ebebeb;
    }

    .accordion-content {
        padding: 10px;
        display: none;
        background-color: #fff;
        border-radius: 4px;
    }

    .accordion-content.active {
        display: block;
    }

    .accordion > .table-responsive {
        margin-bottom: 20px;
    }

    .accordion > .table-responsive:last-child {
        margin-bottom: 0;
    }

    .first-col {
        padding-left: 15px !important;
    }
`;

const PermissionMatrix:FC = () => {

    const [categorys, setCategorys] = useState<any[]>([]);
    const [showPermissionsModal, setShowPermissionsModal] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const [activeCategory, setActiveCategory] = useState<string | null>(null);

    useEffect(() => {
        update();
    }, []);

    async function update() {
        const response = await API.getAllCategorys();
        const data:any = response.data.data;
        setCategorys(data);
    }

    const handleCategoryClick = async(categoryId: string) => {
        setActiveCategory(categoryId === activeCategory ? null : categoryId);
    };

    async function productClick(id_product:number, id_company:number, checked:boolean) {
        if (checked) {
            await API.addProductCompanyPermission({id_product, id_company});
        } else {
            await API.removeProductCompanyPermission({id_product, id_company});
        }
    }

    async function subProductClick(id_subproduct:number, id_company:number, checked:boolean) {
        if (checked) {
            await API.addSubProductCompanyPermission({id_subproduct, id_company});
        } else {
            await API.removeSubProductCompanyPermission({id_subproduct, id_company});
        }
    }

    async function onPermissionModalRequest(selectAll:boolean) {
        if (selectedItem.id_subproduct) {
            selectAll ? await API.selectAllCompanysIntoSubproduct(selectedItem.id_subproduct) : await API.unselectAllCompanysIntoSubproduct(selectedItem.id_subproduct);
        } else {
            selectAll ? await API.selectAllCompanysIntoProduct(selectedItem.id_product) : await API.unselectAllCompanysIntoProduct(selectedItem.id_product);
        }
        await update();
        setShowPermissionsModal(false);
    }

    return <StyledData>
        <PageTitle>Permissões</PageTitle>
        <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-400px' show={showPermissionsModal}>
            <PermissionsModal item={selectedItem} onChange={(value:boolean) => { onPermissionModalRequest(value)}} onClose={() => { 
                setShowPermissionsModal(false);
            }}/>
        </Modal>
        { categorys.map((category) => {
            const isActive = category.id_category === activeCategory;
            return (
            
            <div key={`accordion_category_${category.id_category}`} className='accordion'>
                <div className='accordion-item'>
                    <div className={`accordion-title ${isActive ? 'active' : ''}`} onClick={() => handleCategoryClick(category.id_category)}>
                        <h4 className='fw-bold text-muted justify-content-center'>
                            {category.admin_name ? `${category.admin_name}` : category.name}
                        </h4>
                    </div>
                    {isActive && (
                        <CompanyPermissions categoryId={activeCategory as string}/>
                    )}
                </div>
            </div>
            )})}
    </StyledData>

}

export default PermissionMatrix;