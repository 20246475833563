import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as API from '../../api';
import { getUserData } from '../../modules/auth/core/Auth';
import BtnClose from '../../components/BtnClose';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const StyledModal = styled.div`
    .card-header {
        align-items: center;
    }

    .pdfViewer {
        object {
            width: 100%;
            height: 60vh;
            min-height: 400px;
        }

        embed {
            width: 100%;
            height: 60vh;
            min-height: 400px;
        }
    }

    .card-header {
        margin-bottom: 0px !important;
    }

    .button-confirm {
        width: 200px;
    }
`;

interface Props {
    onClose: Function,
    path: string,
}

const GuidelineViewOnlyModal:FC<Props> = (props:Props) => {
    const [checkbox, setCheckbox] = useState(false);
    const [loading, setLoading] = useState(false)

    const newTitle = 'Para continuar utilizando nossos serviços, é necessário que você esteja de acordo com nossos Termos de Uso. Por favor, leia com atenção e aceite para prosseguir';
    const updateTitle = 'Nossos Termos de Uso foram atualizados. Por favor, leia os novos termos e aceite para continuar a usar nossos serviços';

    const getExtension = () => {
        const parts = props.path.split('.');
        return parts[parts.length - 1];
    }

    const isImage = () => {
        const extension = getExtension();
        return extension === 'jpg' || extension === 'jpeg' || extension === 'png';
    }

    const isPDF = () => {
        const extension = getExtension();
        return extension === 'pdf';
    }

    const confirmGuidelines = async() => {
        setLoading(true);
        await API.acceptGuidelines();
        window.location.reload();
    }

    const onCheckChange = (value:any) => {
        setCheckbox(value);
    }

    const getGuidelines = async() => {
            const response = await API.getGuidelines();
            if (!response.data) return;
            const userData = getUserData();
            console.log(userData.guidelines, response.data);
        }

        useEffect(() => {
            getGuidelines();
        }, []);

    return (
    <StyledModal>
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Termos de Uso</h5>
                <BtnClose onClick={() => props.onClose()} />
            </div>
            <div className="modal-body">
            { isPDF() ? (  
                <div className='pdfViewer'>
                    <object data={props.path} type="application/pdf">
                        <embed src={props.path} type="application/pdf"></embed>
                    </object>
                </div>) : (
                <div>
                    <img alt='Imagem' className='mh-500px mw-500px' src={props.path} />
                </div> )}
            </div>
        </div>
    </StyledModal>
    )
}

export default GuidelineViewOnlyModal