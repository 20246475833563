import { FC, useState, useEffect } from 'react';
import BtnClose from '../../components/BtnClose';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import ReportFilter from './ReportFilter';
import { useDistributors, useRegions, useIndustries } from '../../../app/modules/auth';
import { FilterContainer } from './shared/FilterStyles';
import LoadingSpinner from './shared/LoadingSpinner';
import * as API from '../../../app/api';

const UltimoPrecoEstoqueFilter: FC<any> = (props) => {
    const distributors = useDistributors();
    const industries = useIndustries();

    const [grupos, setGrupos] = useState<any[]>([]);
    const [marcas, setMarcas] = useState<any[]>([]);
    const [familias, setFamilias] = useState<any[]>([]);
    const [tipoPrecos, setTipoPrecos] = useState<any[]>([]);
    const [integracoes, setIntegracoes] = useState<any[]>([]);
    const [politicaPrecos, setPoliticaPrecos] = useState<any[]>([]);

    const [loading, setLoading] = useState(false);
    const [selectedDistributors, setSelectedDistributors] = useState<any[]>([]);
    const [selectedIndustries, setSelectedIndustries] = useState<any[]>([]);
    
    const [selectedGrupos, setSelectedGrupos] = useState<any[]>([]);
    const [selectedMarcas, setSelectedMarcas] = useState<any[]>([]);
    const [selectedFamilia, setSelectedFamilia] = useState<any[]>([]);
    const [selectedTipoPreco, setSelectedTipoPreco] = useState<any[]>([]);
    const [selectedIntegracao, setSelectedIntegracao] = useState<any[]>([]);
    const [selectedPoliticaPreco, setSelectedPoliticaPreco] = useState<any[]>([]);

    const [status, setStatus] = useState<string>(props.type == 'preco' ? 'preco' : 'estoque');
    const [statusProduto, setStatusProduto] = useState<string>('');
    const [cnpj, setCNPJ] = useState<string>('');
    const [idSKU, setIdSKU] = useState<string>('');
    const [EAN, setEAN] = useState<string>('');
    const [produto, setProduto] = useState<string>('');
    const [grupo, setGrupo] = useState<string>('');
    const [familiaProduto, setFamiliaProduto] = useState<string>('');
    const [marca, setMarca] = useState<string>('');
    const [politicaPreco, setPoliticaPreco] = useState<string>('');
    const [dataNFIntegracao, setDataNFIntegracao] = useState<string>('');
    const [filterByDate, setFilterByDate] = useState<string>('');
    const [tipoPreco, setTipoPreco] = useState<string>('');
    const [valorPreco, setValorPreco] = useState<string>('');
    const [estoque, setEstoque] = useState<string>('');
    const [integracao, setIntegracao] = useState<string>('');
    const [filter, setFilter] = useState<any>(null);
    const [dropdownFilters, setDropdownFilters] = useState<any>(null);
    const [startDate, setStartDate] = useState<Date>(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)));
    
    const minDate = new Date(new Date((new Date()).setMonth((new Date()).getMonth() - 18)));
    const maxDate = new Date();

    useEffect(() => {
        setLoading(false);
        loadDropdownFilters();
    }, []);
    
    async function loadDropdownFilters() {
        const response = await API.getUltimoPrecoEstoqueFilters();
        setDropdownFilters(response.data);
        setPoliticaPrecos(response.data.politicaPreco.map((item: any) => ({
            value: item,
            label: item,
        })));
        setGrupos(response.data.grupo.map((item: any) => ({
            value: item,
            label: item,
        })));
        setFamilias(response.data.familia.map((item: any) => ({
            value: item,
            label: item,
        })));
        setMarcas(response.data.marca.map((item: any) => ({
            value: item,
            label: item,
        })));
        setTipoPrecos(response.data.tipoPreco.map((item: any) => ({
            value: item,
            label: item,
        })));
        setIntegracoes(response.data.integracao.map((item: any) => ({
            value: item,
            label: item,
        })));
    }

    useEffect(() => {
        clear();
        if (filter || props.data) {
            const data = filter?.data || props.data;
            if (data.dtstart) setStartDate(new Date(new Date(data.dtstart).setDate(new Date(data.dtstart).getDate() + 1)));
            if (data.status) setStatus(data.status);
            if (data.idSKU) setIdSKU(data.idSKU);
            if (data.ean) setEAN(data.ean);
            if (data.produto) setProduto(data.produto);
            
            if (data.industries) setSelectedIndustries(data.industries);
            if (data.distributors) setSelectedDistributors(data.distributors);
            if (data.politicaPreco) setPoliticaPreco(data.politicaPreco);
            if (data.filterByDate) setFilterByDate(data.filterByDate);
            if (data.statusProduto) setStatusProduto(data.statusProduto);            
            
            if (data.familiaProduto) setSelectedFamilia(data.familiaProduto);
            if (data.marca) setSelectedMarcas(data.marca);
            if (data.grupo) setSelectedGrupos(data.grupo);
            if (data.tipoPreco) setSelectedTipoPreco(data.tipoPreco);
            if (data.integracao) setSelectedIntegracao(data.integracao);

            if (data.valorPreco) setValorPreco(data.valorPreco);
            if (data.estoque) setEstoque(data.estoque);
            
        }
    }, [props.data, filter]);

    function clear() {
        setStatus(props.type == 'preco' ? 'preco' : 'estoque');
        setSelectedDistributors([]);
        setSelectedIndustries([]);
        setStartDate(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)));
        setIdSKU('');
        setProduto('');
        setPoliticaPreco('');
        setEAN('');        
        
        setFilterByDate('');

        setSelectedTipoPreco([]);
        setSelectedIntegracao([]);
        setSelectedMarcas([]);
        setSelectedGrupos([]);
        setSelectedFamilia([]);

        setFilterByDate('');
        setStatusProduto('');
    }

    function getData() {
        return {
            status,
            distributors: selectedDistributors,
            industries: selectedIndustries,
            dtstart: getStringDate(startDate),
            idSKU,
            ean: EAN,
            produto,
            familiaProduto: selectedFamilia,
            marca: selectedMarcas,
            grupo: selectedGrupos,
            politicaPreco,
            filterByDate,
            tipoPreco: selectedTipoPreco,
            valorPreco,
            estoque,
            integracao: selectedIntegracao,
            statusProduto
        }
    }

    function getStringDate(date: Date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
    
        const dayFormatted = day < 10 ? '0' + day : day.toString();
        const monthFormatted = month < 10 ? '0' + month : month.toString();
    
        return `${year}-${monthFormatted}-${dayFormatted}`;
    }

    return (
        <FilterContainer>
            <div className='modal-container'>
                <div className='modal-header'>
                    <h2 className='modal-title'>Filtros para o relatório</h2>
                    <BtnClose onClick={() => props.onClose()} />    
                </div>
                
                <ReportFilter name={props.type} filter={getData()} setFilter={setFilter} />
                
                <div className='form-section'>
                    
                    <div className='form-grid'>
                        <div className='form-group full-width'>
                            <label className='form-label'>Filtrar por data</label>
                            <div className='radio-group'>
                                <div className='radio-item'>
                                    <input 
                                        type='radio'
                                        id='date-yes'
                                        name='filterByDate'
                                        value='SIM'
                                        checked={filterByDate === 'SIM'}
                                        onChange={({ target: { value } }) => setFilterByDate(value)}
                                    />
                                    <label htmlFor='date-yes'>Sim</label>
                                </div>
                                <div className='radio-item'>
                                    <input
                                        type='radio'
                                        id='date-no'
                                        name='filterByDate'
                                        value=''
                                        checked={filterByDate === ''}
                                        onChange={({ target: { value } }) => setFilterByDate(value)}
                                    />
                                    <label htmlFor='date-no'>Não</label>
                                </div>
                            </div>
                        </div>

                        {filterByDate === 'SIM' && (
                            <div className='form-group full-width'>
                                <label className='form-label'>Selecionar Data</label>
                                <div className='date-picker-wrapper'>
                                    <select
                                        disabled={loading}
                                        className='form-select'
                                        value={status}
                                        onChange={(evt) => setStatus(evt.target.value)}
                                    >
                                        <option value='preco'>Preço</option>
                                        <option value='estoque'>Estoque</option>
                                    </select>
                                    <DatePicker
                                        disabled={loading}
                                        dateFormat='dd/MM/yyyy'
                                        selected={startDate}
                                        onChange={(date: any) => setStartDate(date)}
                                        className='form-control'
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        placeholderText="Selecione uma data"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='section-divider' />
                    

                    <div className='form-grid'>
                        <div className='form-group full-width'>
                            <label className='form-label'>Indústria</label>
                            <Select
                                isMulti
                                isDisabled={loading}
                                options={industries}
                                value={selectedIndustries}
                                placeholder='Filtrar por Indústria'
                                closeMenuOnSelect={false}
                                onChange={(selected: any) => setSelectedIndustries(selected)}
                                className='select-container'
                                classNamePrefix='select'
                            />
                        </div>

                        <div className='form-group full-width'>
                            <label className='form-label'>Distribuidor</label>
                            <Select
                                isMulti
                                isDisabled={loading}
                                options={distributors}
                                value={selectedDistributors}
                                placeholder='Filtrar por Distribuidor'
                                closeMenuOnSelect={false}
                                onChange={(selected: any) => setSelectedDistributors(selected)}
                                className='select-container'
                                classNamePrefix='select'
                            />
                        </div>
                    </div>


                    <div className='section-divider' />
                    
                    
                    <div className='form-grid'>
                        <div className='form-group'>
                            <label className='form-label'>SKU</label>
                            <input
                                className='form-control'
                                type='text'
                                disabled={loading}
                                value={idSKU}
                                onChange={(e) => setIdSKU(e.target.value)}
                                placeholder="Digite o SKU"
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>EAN</label>
                            <input
                                className='form-control'
                                type='text'
                                disabled={loading}
                                value={EAN}
                                onChange={(e) => setEAN(e.target.value)}
                                placeholder="Digite o EAN"
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Nome do Produto</label>
                            <input
                                className='form-control'
                                type='text'
                                disabled={loading}
                                value={produto}
                                onChange={(e) => setProduto(e.target.value)}
                                placeholder="Digite o nome do produto"
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Família do Produto</label>
                            <Select
                                isMulti
                                isDisabled={loading}
                                options={familias}
                                value={selectedFamilia}
                                placeholder='Filtrar por Família'
                                closeMenuOnSelect={false}
                                onChange={(selected: any) => setSelectedFamilia(selected)}
                                className='select-container'
                                classNamePrefix='select'
                            />
                            { /*
                            <input
                                className='form-control'
                                type='text'
                                disabled={loading}
                                value={familiaProduto}
                                onChange={(e) => setFamiliaProduto(e.target.value)}
                                placeholder="Digite a família do produto"
                            /> */}
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Marca</label>
                            <Select
                                isMulti
                                isDisabled={loading}
                                options={marcas}
                                value={selectedMarcas}
                                placeholder='Filtrar por Marca'
                                closeMenuOnSelect={false}
                                onChange={(selected: any) => setSelectedMarcas(selected)}
                                className='select-container'
                                classNamePrefix='select'
                            />
                            {/*
                            <input
                                className='form-control'
                                type='text'
                                disabled={loading}
                                value={marca}
                                onChange={(e) => setMarca(e.target.value)}
                                placeholder="Digite a marca"
                            /> */ }
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Grupo</label>
                            <Select
                                isMulti
                                isDisabled={loading}
                                options={grupos}
                                value={selectedGrupos}
                                placeholder='Filtrar por Grupo'
                                closeMenuOnSelect={false}
                                onChange={(selected: any) => setSelectedGrupos(selected)}
                                className='select-container'
                                classNamePrefix='select'
                            />
                            { /*
                            <input
                                className='form-control'
                                type='text'
                                disabled={loading}
                                value={grupo}
                                onChange={(e) => setGrupo(e.target.value)}
                                placeholder="Digite o grupo"
                            /> */}
                        </div>
                    </div>

                    
                    <div className='section-divider' />
                    

                    <div className='form-grid'>
                        <div className='form-group'>
                            <label className='form-label'>Política de Preço</label>
                            <input
                                className='form-control'
                                type='text'
                                disabled={loading}
                                value={politicaPreco}
                                onChange={(e) => setPoliticaPreco(e.target.value)}
                                placeholder="Digite a política de preço"
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Tipo de Preço</label>
                            <Select
                                isMulti
                                isDisabled={loading}
                                options={tipoPrecos}
                                value={selectedTipoPreco}
                                placeholder='Tipo de Preço'
                                closeMenuOnSelect={false}
                                onChange={(selected: any) => setSelectedTipoPreco(selected)}
                                className='select-container'
                                classNamePrefix='select'
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Valor do Preço</label>
                            <input
                                className='form-control'
                                type='text'
                                disabled={loading}
                                value={valorPreco}
                                onChange={(e) => setValorPreco(e.target.value)}
                                placeholder="Digite o valor do preço"
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Estoque</label>
                            <input
                                className='form-control'
                                type='text'
                                disabled={loading}
                                value={estoque}
                                onChange={(e) => setEstoque(e.target.value)}
                                placeholder="Digite o estoque"
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Integração</label>
                            <Select
                                isMulti
                                isDisabled={loading}
                                options={integracoes}
                                value={selectedIntegracao}
                                placeholder='Integração'
                                closeMenuOnSelect={false}
                                onChange={(selected: any) => setSelectedIntegracao(selected)}
                                className='select-container'
                                classNamePrefix='select'
                            />{/*
                            <input
                                className='form-control'
                                type='text'
                                disabled={loading}
                                value={integracao}
                                onChange={(e) => setIntegracao(e.target.value)}
                                placeholder="Digite a integração"
                            /> */}
                        </div>

                        <div className='form-group full-width'>
                            <label className='form-label'>Status do Produto</label>
                            <div className='radio-group'>
                                <div className='radio-item'>
                                    <input
                                        type='radio'
                                        id='status-todos'
                                        name='statusProduto'
                                        value=''
                                        checked={statusProduto === ''}
                                        onChange={({ target: { value } }) => setStatusProduto(value)}
                                    />
                                    <label htmlFor='status-todos'>Todos</label>
                                </div>
                                <div className='radio-item'>
                                    <input
                                        type='radio'
                                        id='status-online'
                                        name='statusProduto'
                                        value='SIM'
                                        checked={statusProduto === 'SIM'}
                                        onChange={({ target: { value } }) => setStatusProduto(value)}
                                    />
                                    <label htmlFor='status-online'>Online</label>
                                </div>
                                <div className='radio-item'>
                                    <input
                                        type='radio'
                                        id='status-offline'
                                        name='statusProduto'
                                        value='NAO'
                                        checked={statusProduto === 'NAO'}
                                        onChange={({ target: { value } }) => setStatusProduto(value)}
                                    />
                                    <label htmlFor='status-offline'>Offline</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button
                        onClick={() => {
                            setLoading(true);
                            props.onChange(getData());
                        }}
                        className='filter-btn'
                        disabled={loading}
                    >
                        {loading ? (
                            <>
                                Aguarde
                                <LoadingSpinner />
                            </>
                        ) : (
                            <>
                                Aplicar Filtros
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 6H20M6 12H18M8 18H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </>
                        )}
                    </button>
                </div>
            </div>
        </FilterContainer>
    );
}

export default UltimoPrecoEstoqueFilter;