import React from 'react';
import { CompanyRow } from './CompanyRow';
import { FeatureHeader } from './FeatureHeader';

type FeatureTableProps = {
  features: any[];
  companies: any[];
  permissions: any[];
  index: number;
  onFeatureClick: (feature: any) => void;
  onPermissionChange: (featureId: number, companyId: number, checked: boolean) => void;
};

export const FeatureTable = ({
  features,
  companies,
  permissions,
  onFeatureClick,
  onPermissionChange,
  index
}: FeatureTableProps) => (
  <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky left-0 bg-gray-50">
              Empresa (A-Z)
            </th>
            {features.map(feature => (
              <FeatureHeader
                key={feature.id_feature}
                feature={feature}
                onFeatureClick={onFeatureClick}
              />
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {companies
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(company => (
              <CompanyRow
                key={company.id_company + "_" + index}
                company={company}
                features={features}
                permissions={permissions}
                onPermissionChange={onPermissionChange}
              />
            ))}
        </tbody>
      </table>
    </div>
  </div>
);