import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.div`
    &:hover {
        i,
        .svg-icon {
            opacity: 0.3
        }
    }
`;

interface Props {
    onClick?: Function
}

const BtnBookmark:React.FC<Props> = (props:Props) => {
    return <StyledButton>
        <div onClick={() => props.onClick && props.onClick()}>
            <div className="btn btn-sm btn-icon btn-active-color-primary">
                <span className="svg-icon svg-icon-1">
                <svg fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="-38.13 -38.13 457.58 457.58" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" stroke="#030303" strokeWidth="38.1322"> <g> <path d="M296.582,6.053v369.21c0,2.376-1.383,4.516-3.534,5.503c-0.804,0.372-1.667,0.55-2.518,0.55 c-1.419,0-2.838-0.503-3.961-1.472l-95.907-82.84l-95.912,82.84c-1.797,1.554-4.327,1.921-6.475,0.922 c-2.148-0.987-3.535-3.127-3.535-5.503V6.053C84.741,2.704,87.445,0,90.793,0H290.53C293.875,0,296.582,2.704,296.582,6.053z"></path> </g> </g><g id="SVGRepo_iconCarrier"> <g> <path d="M296.582,6.053v369.21c0,2.376-1.383,4.516-3.534,5.503c-0.804,0.372-1.667,0.55-2.518,0.55 c-1.419,0-2.838-0.503-3.961-1.472l-95.907-82.84l-95.912,82.84c-1.797,1.554-4.327,1.921-6.475,0.922 c-2.148-0.987-3.535-3.127-3.535-5.503V6.053C84.741,2.704,87.445,0,90.793,0H290.53C293.875,0,296.582,2.704,296.582,6.053z"></path> </g> </g></svg>
                </span>
            </div>
        </div>
    </StyledButton>
}

export default BtnBookmark;

