import React from 'react';

type CompanyRowProps = {
  company: any;
  features: any[];
  permissions: any[];
  onPermissionChange: (featureId: number, companyId: number, checked: boolean) => void;
};

export const CompanyRow = ({ company, features, permissions, onPermissionChange }: CompanyRowProps) => (
  <tr className="hover:bg-gray-50 transition-colors">
    <td className="px-6 py-4 whitespace-nowrap bg-white sticky left-0">
      <span className="text-sm font-medium text-gray-900">{company.name}</span>
    </td>
    
    {features.map(feature => (
      <td key={`${feature.id_feature}_${company.id_company}`} className="px-6 py-4 text-center">
        <input
          type="checkbox"
          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 transition-colors"
          defaultChecked={permissions.some(p => 
            p.id_feature === feature.id_feature && p.id_company === company.id_company
          )}
          onChange={(e) => onPermissionChange(feature.id_feature, company.id_company, e.target.checked)}
        />
      </td>
    ))}
  </tr>
);