export enum AddMenuItemType { 
  CATEGORY, 
  PRODUCT, 
  SUBPRODUCT,
}

export interface AddMenuItemData {
  categories: Array<any> | undefined,
  products: Array<any> | undefined | null,
  subProducts: Array<any> | undefined | null,
  category: any | undefined,
  product: any | undefined,
  subProduct: any | undefined,
}

export const defaultAddMenuItemData: AddMenuItemData = {
  categories: undefined,
  products: undefined,
  subProducts: undefined,
  category: undefined,
  product: undefined,
  subProduct: undefined,
}

export type AddMenuItemTypeData = {
  inputName: string,
  inputPlaceholder: string,
  label: string,
  option: string,
}

export type AddMenuItemProps = {
  data: Array<any> | undefined,
  type: AddMenuItemType,
  typeData: AddMenuItemTypeData | undefined,
  onSelectedItem: (type: AddMenuItemType, item: any) => void,
  onAddingItem: (type: AddMenuItemType, item: string) => void,
  onAddingMode: (type: AddMenuItemType, adding: boolean) => void,
  onDeletingItem: (type: AddMenuItemType, item: any) => void,
}

export type InputAddMenuItemTypeData = {
  label: string,
  inputName: string,
  inputPlaceholder: string,
}

export type InputAddMenuItemProps = {
  typeData: InputAddMenuItemTypeData | undefined,
  onSavingItem: (newItem: string) => void,
}
