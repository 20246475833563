import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { FileSpreadsheet, Filter, Loader2, HelpCircle } from 'lucide-react';
import * as API from '../../api';
import FilterModal from './politicaPreco/PoliticaPrecoFilter';
import DownloadModal from './DownloadModal';
import ManualModal from "../utils/ManualModal";
import ViewerModal from "../utils/ViewerModal";
import { isSuperAdmin } from '../../modules/auth';
import { ReportType } from '../../types/report-type';
import { StatsCard } from './politicaPreco/StatsCard';

const REPLACE_COLUMNS_NAME = [
  ['IDPoliticaPreco', 'ID Política Preço'],
  ['UnidadeDeNegocio', 'Unidade de Negócio'],
  ['CNPJLojista', 'CNPJ'],
  ['NomeLojista', 'Lojista'],
  ['NomeDistribuidor', 'Distribuidor'],
  ['NomeIndustria', 'Indústria'],
  ['CepInicio', 'CEP Início'],
  ['CepFim', 'CEP Fim'],
  ['AtendimentoEspecial', 'Atendimento Especial'],
  ['FLBlackList', 'FL BlockList'],
  ['PoliticaDePreco', 'Política de Preço'],
  ['DataHoraPreco', 'Data Preço'],
  ['IDSku', 'ID SKU'],
  ['NomeProduto', 'Produto'],
  ['NomeMarca', 'Marca'],
  ['TipoPreco', 'Preço'],
  ['PrazoPagamento', 'Prazo de Pagamento'],
  ['ValorPreco', 'Valor Preço']
];

const ReportPoliticaPreco: React.FC<{ className: string }> = ({ className }) => {
  const [columns, setColumns] = useState<string[]>([]);
  const [filters, setFilters] = useState<any>(null);
  const [downloadURL, setDownloadURL] = useState<string>('');
  const [jobId, setJobId] = useState<any>(null);
  const [downloadError, setDownloadError] = useState(false);
  const [data, setData] = useState<any>(null);
  const [counter, setCounter] = useState(0);
  const [filterModal, showFilterModal] = useState<boolean>(true);
  const [downloadModal, showDownloadModal] = useState<boolean>(false);
  const [manual, setManual] = useState<any>(null);
  const [manualModal, showManualModal] = useState<boolean>(false);
  const [viewerModal, showViewerModal] = useState<boolean>(false);
  const [limit, setLimit] = useState(100);
  const [isLoading, setIsLoading] = useState(true);

  const firstUpdate = useRef(true);
  const status: string = 'captado';
  const startDate: Date = new Date((new Date()).setDate((new Date()).getDate() - 7));
  const endDate: Date = new Date();
  const type: ReportType = 'politica_preco';

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setData(null);
    loadData(filters);
  }, [limit]);

  useEffect(() => {
    if (!manualModal) {
      getManualReport();
    }
  }, [manualModal]);

  function getStringDate(date:Date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const dayFormatted = day < 10 ? '0' + day : day.toString();
    const monthFormatted = month < 10 ? '0' + month : month.toString();

    return `${year}-${monthFormatted}-${dayFormatted}`;
}

function isIsoDateString(value:any) {
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}(?:[ T]\d{2}:\d{2}:\d{2}(?:\.\d{3}Z)?)?$/;
    return isoDateRegex.test(value);
}

function convertToBrazilianDate(isoDate:any) {
    if (typeof isoDate !== 'string') {
        return null;
    }
    const [datePart, timePartWithExtras] = isoDate.split('T').length > 1 ? isoDate.split('T') : isoDate.split(' ');
    const [year, month, day] = datePart.split('-');
    const timePart = timePartWithExtras ? timePartWithExtras.split('.')[0].split('Z')[0] : '00:00:00';
    const [hour, minute, second] = timePart.split(':');
    return `${day}/${month}/${year}`;
}

function filterData(data:any) {
    return data.map((row:any) => {
        const length = row.length;
        for (let a = 0; a < length; a++) {
            if (isIsoDateString(row[a])) row[a] = convertToBrazilianDate(row[a]);
        }
        return row;
    });
}

function applyFilters(data:any) {
    setData(null);
    setFilters(data);
    loadData(data);
}

async function exportXLSX() {
    setDownloadError(false);
    showFilterModal(false);
    showDownloadModal(true);
    if (counter > 2000000) return;
    const response = await API.getReport(filters ? filters : {status, dtstart:getStringDate(startDate), dtend:getStringDate(endDate)}, type, true, limit);
    if (!response || !response.success) {
        setDownloadError(true);
    } else {
        //setDownloadURL(response.link);
        setJobId(response.data);
    }
}

function handleHelpClick() {
    if (isSuperAdmin()) {
        showManualModal(true);
    } else {
        showViewerModal(true);
    }
}

function filterColumnsName(columns:any) {
    if (!columns) return;
    REPLACE_COLUMNS_NAME.map((item) => {
        const length = columns.length;
        for (let a = 0; a < length; a++) {
            if (columns[a] == item[0]) columns[a] = item[1];
        }
    })
    return columns;
}

  const loadData = async (data: any = null) => {
    setIsLoading(true);
    try {
      const response = await API.getReport(
        data || { status, dtstart: getStringDate(startDate), dtend: getStringDate(endDate) },
        type,
        false,
        limit
      );
      setColumns(filterColumnsName(response.columns));
      setData(filterData(response.data));
      setCounter(response.counter);
      showFilterModal(false);
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getManualReport = async () => {
    const response = await API.getManualReport(type);
    if (response?.data?.length) {
      setManual(response.data[0]);
    }
  };

  // Helper functions remain the same...

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold text-gray-900">
              Relatório de Política de Preços
            </h1>
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-500">Limite:</span>
                <select
                  className="form-select rounded-lg border-gray-200 text-sm"
                  value={limit}
                  onChange={(e) => setLimit(Number(e.target.value))}
                >
                  <option value={100}>100</option>
                  <option value={250}>250</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                </select>
              </div>

              <button
                onClick={() => showFilterModal(true)}
                className="inline-flex items-center px-4 py-2 bg-white text-gray-700 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors duration-200"
              >
                <Filter className="w-5 h-5 mr-2" />
                Filtros
              </button>

              <button
                onClick={exportXLSX}
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
              >
                <FileSpreadsheet className="w-5 h-5 mr-2" />
                Exportar XLSX
              </button>

              {(isSuperAdmin() || manual) && (
                <button
                  onClick={handleHelpClick}
                  className={`p-2 rounded-lg transition-colors duration-200 ${
                    manual ? 'text-blue-600 hover:bg-blue-50' : 'text-red-500 hover:bg-red-50'
                  }`}
                >
                  <HelpCircle className="w-6 h-6" />
                </button>
              )}
            </div>
          </div>

          {data && (
            <div className="mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              <StatsCard
                title="Total de Resultados"
                value={counter}
                description="Total de registros encontrados"
                icon={<FileSpreadsheet className="w-6 h-6" />}
              />
              <StatsCard
                title="Resultados Visíveis"
                value={data.length}
                description="Registros exibidos na tabela"
                icon={<FileSpreadsheet className="w-6 h-6" />}
              />
            </div>
          )}
        </div>

        {/* Table */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          <div className="overflow-x-auto">
            {isLoading ? (
              <div className="flex flex-col items-center justify-center py-12">
                <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
                <p className="mt-4 text-gray-500">Carregando dados...</p>
              </div>
            ) : !data?.length ? (
              <div className="flex flex-col items-center justify-center py-12">
                <div className="bg-gray-100 rounded-full p-3">
                  <FileSpreadsheet className="w-8 h-8 text-gray-400" />
                </div>
                <p className="mt-4 text-gray-500">Nenhum registro encontrado</p>
              </div>
            ) : (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {columns?.map((column, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data?.map((row: any, rowIndex: number) => (
                    <tr key={rowIndex} className="hover:bg-gray-50 transition-colors duration-150">
                      {row.map((value: any, valueIndex: number) => (
                        <td
                          key={valueIndex}
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      {/* Modals */}
      <Modal show={downloadModal} onHide={() => showDownloadModal(false)} centered>
      <DownloadModal url={downloadURL} jobId={jobId} error={downloadError} counter={counter} onClose={() => {
                    showDownloadModal(false);
                    setDownloadURL('');
                }}/>
      </Modal>

      <Modal show={filterModal} onHide={() => showFilterModal(false)} centered>
        <FilterModal
          data={filters}
          type={type}
          onChange={applyFilters}
          onClose={() => showFilterModal(false)}
        />
      </Modal>

      <Modal show={manualModal} onHide={() => showManualModal(false)} centered>
        <ManualModal
          onClose={() => {
            showManualModal(false);
            getManualReport();
          }}
          type={type}
          item={manual}
        />
      </Modal>

      <Modal show={viewerModal} onHide={() => showViewerModal(false)} centered>
        {manual && <ViewerModal onClose={() => showViewerModal(false)} path={manual.path} />}
      </Modal>
    </div>
  );
};

export default ReportPoliticaPreco;