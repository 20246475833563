import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as API from '../../api';
import PermissionsModal from './PermissionsModal';
import {Modal} from 'react-bootstrap';
import Select from 'react-select';

const StyledData = styled.div`
    .remove-user-title {
        margin:20px !important;
    }

    .table {
        
    }

    .card:hover {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    }

    .table-row-dashed tbody tr {
        transition: background-color 0.15s ease-out;
    }

    .table-row-dashed tbody tr:hover {
        background-color: #f6f6f6;
        
    }

    .accordion {
        width: 100%;
        margin: 20px auto;
    }

    .accordion-item {
        border-bottom: 1px dashed #ccc;
        margin-bottom: 10px;
    }

    .accordion-title {
        padding: 10px;
        cursor: pointer;
        background-color: #f9f9f9;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .accordion-title:hover {
        background-color: #ebebeb;
    }

    .accordion-title.active {
        background-color: #ebebeb;
    }

    .accordion-content {
        padding: 10px;
        display: none;
        background-color: #fff;
        border-radius: 4px;
    }

    .accordion-content.active {
        display: block;
    }

    .accordion > .table-responsive {
        margin-bottom: 20px;
    }

    .accordion > .table-responsive:last-child {
        margin-bottom: 0;
    }

    .first-col {
        padding-left: 15px !important;
    }




     .table-container {
        overflow-y: auto;
        overflow-x: auto;
        max-height: 70vh;
        margin: 20px;
    }

    .table {
        width: 100%;
        border-collapse: collapse;
    }
    
    thead th {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1000; /* Muito alto para garantir prioridade */
    }
  
    .first-col,
    .first-col-header {
        position: sticky;
        left: 0;
        background-color: white;
    }

    .table-row-dashed tbody tr {
        transition: background-color 0.15s ease-out;
    }

    .table-row-dashed tbody tr:hover {
        background-color: #f6f6f6;
    }

    .filter-group {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
        z-index: 1001;
    }

    .filter-radio {
        margin-right: 10px;
    }

    .filter-dropdown {
        position: relative;
    }

    .filter-dropdown-input {
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 150px;
        margin-right: 5px;
    }

    .filter-dropdown-options {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        max-height: 200px;
        overflow-y: auto;
        display: none;
    }

    .filter-dropdown-option {
        padding: 5px 10px;
        cursor: pointer;
    }

    .filter-dropdown-option:hover {
        background-color: #f6f6f6;
    }

    .filter-dropdown:hover .filter-dropdown-options {
        display: block;
    }

    .filter-dropdown-selected {
        color: #333;
    }
`;

const UsersPermissionMatrix:FC = () => {

    const [categorys, setCategorys] = useState<any[]>([]);
    const [showPermissionsModal, setShowPermissionsModal] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const [activeCategory, setActiveCategory] = useState<string | null>(null);

    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [usersData, setUsersData] = useState<any>([]);
    const [users, setUsers] = useState<any[]>([]);

    useEffect(() => {
        update();
    }, []);

    async function update() {
        const response = await API.loadUsersPermissions();
        const data:any = response.data;
        if (data.length === 1) setActiveCategory(data[0].id_category);
        setCategorys(data);
    }

    const handleCategoryClick = (categoryId: string) => {
        setActiveCategory(categoryId === activeCategory ? null : categoryId);
    };

    async function productClick(id_product:number, id_user:number, checked:boolean) {
        if (checked) {
            await API.addProductUserPermission({id_product, id_user});
        } else {
            await API.removeProductUserPermission({id_product, id_user});
        }
    }

    async function subProductClick(id_subproduct:number, id_user:number, checked:boolean) {
        if (checked) {
            await API.addSubProductUserPermission({id_subproduct, id_user});
        } else {
            await API.removeSubProductUserPermission({id_subproduct, id_user});
        }
    }

    async function onPermissionModalRequest(selectAll:boolean) {
        if (selectedItem.id_subproduct) {
            selectAll ? await API.selectAllUsersIntoSubproduct(selectedItem.id_subproduct) : await API.unselectAllUsersIntoSubproduct(selectedItem.id_subproduct);
        } else {
            selectAll ? await API.selectAllUsersIntoProduct(selectedItem.id_product) : await API.unselectAllUsersIntoProduct(selectedItem.id_product);
        }
        await update();
        setShowPermissionsModal(false);
    }

    function getUsersBySelectedCategory() {
        if (!activeCategory) return null;
        for (let a = 0; a < categorys.length; a++) {
            if (categorys[a].id_category == activeCategory) return categorys[a].users;
        }
    }

    useEffect(() => {
        if (!activeCategory) return;
        setSelectedUsers([]);
        const data:any[] = [];
        const users = getUsersBySelectedCategory();
        users.map((item: any) => {
            const obj: { [key: string]: any; value: any; label: any } = {
                value: item.id_user,
                label: item.name
            };
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    obj[key] = item[key];
                }
            }
            data.push(obj);
        });
        setUsers(data);
    }, [activeCategory]);

    useEffect(() => {
        if (selectedUsers.length) {
            setUsersData(selectedUsers);
        } else {
            if (activeCategory) setUsersData(getUsersBySelectedCategory());
        }
    }, [selectedUsers, users]);

    return <StyledData>
        <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-400px' show={showPermissionsModal}>
            <PermissionsModal item={selectedItem} onChange={(value:boolean) => { onPermissionModalRequest(value)}} onClose={() => { 
                setShowPermissionsModal(false);
            }}/>
        </Modal>
        { categorys.map((category) => {
            const isActive = category.id_category === activeCategory;
            return (
            
            <div key={`accordion_category_${category.id_category}`} className='accordion'>
                
                <div className='accordion-item'>
                    <div className={`accordion-title ${isActive ? 'active' : ''}`} onClick={() => handleCategoryClick(category.id_category)}>
                        <h4 className='fw-bold text-muted justify-content-center'>
                            {category.admin_name ? `${category.admin_name} (${category.name})` : category.name}
                        </h4>
                    </div>
                    {isActive && (
                        <div className='accordion-content active'>
                            <div className="filter-group">
                                <div className='form-group mb-4 w-100'>
                                    <label className='form-label fw-bold me-2 w-200pxx'>Buscar por Usuários:</label>
                                    <Select
                                        isMulti
                                        options={users}
                                        value={selectedUsers}
                                        closeMenuOnSelect={false}
                                        placeholder="Selecionar usuários"
                                        onChange={(selected: any) => setSelectedUsers(selected)}
                                        className="multi-select"
                                    />
                                </div>
                            </div>
                            <div className="table-wrapper">
                                <div className="table-container">
                                    <table className="table table-row-dashed table-row-gray-300 align-middle justify-content-center">
                                        <thead>
                                            <tr className="fw-bold text-muted">
                                                <th className="w-200px first-col first-col-header">
                                                    <span className='btn'>Usuário</span>
                                                </th>
                                                {category.products.map((product:any) => { return (
                                                    <React.Fragment key={`c_p_${product.id_product}`}>
                                                        <th key={`title_product_${product.id_product}`} className='justify-content-center text-center'>
                                                            <button className='btn btn-active-light-primary' onClick={() => {
                                                                setSelectedItem(product);
                                                                setShowPermissionsModal(true);
                                                            }}>{product.admin_name ? product.admin_name : product.name}</button>
                                                        </th>
                                                        
                                                        { product.subproducts.map((subproduct:any) => { return (
                                                            <th key={`title_product_${subproduct.id_subproduct}`} className='justify-content-center text-center'>
                                                                <button className='btn btn-active-light-primary' onClick={() => {
                                                                    setSelectedItem(subproduct);
                                                                    setShowPermissionsModal(true);
                                                                }}>{subproduct.admin_name ? subproduct.admin_name : subproduct.name}</button>
                                                            </th>
                                                        )})}
                                                    </React.Fragment>
                                                )})}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>{ usersData.map((user:any) => { return (
                                                <tr key={`${user.id_user}_${category.id_user}`} className='justify-content-center'>
                                                    <td className="first-col w-200px">
                                                        <span className='w-200px text-muted fw-semibold text-muted d-block fs-7 first-col'>{user.name}</span>
                                                    </td>
                                                    {category.products.map((product:any) => { return (
                                                        <React.Fragment key={`cc_p_${product.id_product}`}>
                                                            <td key={Math.random()} className="justify-content-center align-middle">
                                                                <div className='form-check form-check-sm form-check-custom d-flex justify-content-center text-center'>
                                                                    <input className='form-check-input widget-9-check ' type='checkbox' defaultChecked={user.products.find((p:any) => { return product.id_product === p.id_product })?.enabled} onChange={(evt) => { productClick(product.id_product, user.id_user, evt.target.checked) }}/>
                                                                </div>
                                                            </td>
                                                            { product.subproducts.map((subproduct:any) => { return (
                                                                <td key={Math.random()} className="justify-content-center align-middle">
                                                                    <div className='form-check form-check-sm form-check-custom d-flex justify-content-center text-center'>
                                                                        <input className='d-flex form-check-input widget-9-check' type='checkbox' defaultChecked={user.subproducts.find((sp:any) => { return subproduct.id_subproduct === sp.id_subproduct })?.enabled} onChange={(evt) => { subProductClick(subproduct.id_subproduct, user.id_user, evt.target.checked) }} />
                                                                    </div>
                                                            </td>
                                                            )})}
                                                        </React.Fragment>
                                                    )})}
                                                </tr>
                                            )})}</>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )})}
    </StyledData>
}

export default UsersPermissionMatrix;