/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { verifyEmail } from '../../../api';

const confirmSchema = Yup.object().shape({})

const initialValues = {}

interface Props {}

export const VerifyEmail: React.FC<Props> = (props:Props) => { 
  const navigate = useNavigate();
  const { token, userGuid, email } = useParams();
  
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  
  const formik = useFormik({
    initialValues,
    validationSchema: confirmSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        if (!token) {
          throw Error("É necessária a autorização para realizar essa operação!");
        }

        if (!userGuid) {
          throw Error("É necessário o usuário para realizar essa operação!");
        }

        if (!email) {
          throw Error("É necessário o email para realizar essa operação!");
        }

        setLoading(true)
        verifyEmail(token, userGuid, email)
          .then((data: any) => {
            setHasErrors(false)
            setTimeout(() => navigate('/auth/login') , 1000)
          })
          .catch((error) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus(error)
          })
      } catch (error: any) {
        setHasErrors(true)
        setStatus(error.message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Confirme seu email</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/* begin::Form group */}
      <div className='text-center'>
        <div className='fv-row mb-10'>
        <label className='form-label text-dark fs-6 mb-0'>{email}</label>
        </div>
      </div>
      
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Confirmar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde por favor...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Desculpe, ocorreram alguns erros! Por favor tente novamente.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Seu email foi confirmado!</div>
          </div>
        )}
      {/* end::Action */}
    </form>
  )
}
