import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import BtnClose from '../../components/BtnClose';
import * as API from '../../api';
import { messages } from '../utils/ErrorsText';
import { isSuperAdmin } from '../../modules/auth/core/Auth';
import { getUserData } from '../../modules/auth/core/Auth';
import IconAttachment from './../../components/IconAttachment';
import BtnView from './../../components/BtnStatusOpen';

const StyledModal = styled.div`
    .card-header {
        align-items: center;
    }

    .button-view {
        position: relative;
        top: 8px;
        cursor: pointer;
    }

    .pdfViewer {
        object {
            width: 100%;
            height: 60vh;
            min-height: 400px;
        }

        embed {
            width: 100%;
            height: 60vh;
            min-height: 400px;
        }
    }
`;

interface Props {
    userData?: any,
    onClose: Function
}

const GuidelineModal:FC<Props> = (props:Props) => {
    const [files, setFiles] = useState<File[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [uploadId, setUploadId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [path, setPath] = useState<string>('');

    const [currentGuideline, setCurrentGuideline] = useState<any>(null);
    const [guidelinesIsLoaded, setGuidelinesIsLoaded] = useState<boolean>(false);
    
    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFiles(Array.from(e.target.files));
            if (e.target.files && e.target.files.length) {
                setIsUploading(true);
                API.uploadFile(e.target.files[0]).then((response) => {
                    console.log(response);
                    setPath(response.data.path);
                    setUploadId(response.data.id_upload);
                    setIsUploading(false);
                })
            }
        }
    };

    async function onSubmit() {
        setLoading(true);
        try {
            await API.addNewGuidelines({
                id_upload:uploadId
            });
            setLoading(false)
            props.onClose();
        } catch (e) {
            console.log(e);
            setLoading(false)
        }
    }

    function onChange() {

    }

    const getGuidelines = async() => {
		const response = await API.getGuidelines();
		if (response.data) setCurrentGuideline(response.data);
        setGuidelinesIsLoaded(true);
	}

    useEffect(() => {
		getGuidelines();
	}, []);

    return (
    <StyledModal className="card mb-5">
        <div className='card-header border-0'>
            <div className='card-title m-0'>
                <h3>Termos de Uso</h3>
            </div>
            <BtnClose onClick={() => props.onClose()} />
        </div>
        
        <div className='card mb-5 mb-xl-10'>        
            <div className='card-body text-center pb-0'>
                { guidelinesIsLoaded ? 
                    <p>{ currentGuideline ? 'Deseja atualizar o termo atual?' : 'Cadastre os termos de uso' }</p>
                : <></> }
                { currentGuideline ? 
                    <div className='pdfViewer'>
                        <object data={currentGuideline.src} type="application/pdf">
                            <embed src={currentGuideline.src} type="application/pdf"></embed>
                        </object>
                    </div> : <></> }
                <div className='d-flex justify-content-center'>
                    
                    <div className='form-item'>
                        <input type='text' placeholder='Termos de Uso' disabled onChange={ () => onChange() } className='form-control form-control-lg form-control-solid' value={files.length ? files[0].name : currentGuideline ? currentGuideline.src : ''}></input>
                    </div>
                    <label className="btn btn-sm btn-icon btn-active-light-primary me-1">
                        <IconAttachment/>
                        <input type="file" accept=".pdf,.png,.jpg,.jpeg" hidden onChange={handleFileSelect} />
                    </label>
                </div>
                <button type='submit' id='kt_sign_in_submit' className='btn btn-lg btn-primary w-50 mb-5 mt-5' disabled={isUploading || !uploadId} onClick={() => { onSubmit() }}>
                    {!loading && <span className='indicator-label' >Salvar</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Aguarde..
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            
            </div>
        </div>
    </StyledModal>
    )
}

export default GuidelineModal