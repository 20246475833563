import React from 'react';

type FeatureHeaderProps = {
  feature: any;
  onFeatureClick: (feature: any) => void;
};

export const FeatureHeader = ({ feature, onFeatureClick }: FeatureHeaderProps) => (
  <th className="justify-content-center text-center">
    <button 
      className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
      onClick={() => onFeatureClick(feature)}
    >
      {feature.title}
    </button>
  </th>
);