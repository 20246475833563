import styled from "@emotion/styled";
import { PageTitle } from "../../../_metronic/layout/core";
import * as API from '../../../app/api';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Chart from '../dashboard/Chart';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar, Pie, Line } from 'react-chartjs-2';

const MonitorWrapper = styled.div`
    position: relative;

    .content-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .content-column {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 95%;
        margin-right: 20px;
    }

    .alerts-list {
        position: relative;
        width: 100%;
        flex-grow: 1;
        margin-bottom: 10px;
    }

    .alert-description {
        position: relative;
        width: 100%;
        height: 200px;
        padding: 15px;
        border: 1px solid #ccc;
        margin-top: 30px;
    }

    .chart-wrapper {
        position: relative; 
        height: 60vh;
    }

    .chart-hidden {
        display: none;
    }
`

const AlertMonitoring:React.FC = () => {

    const [alerts, setAlerts] = useState<any[]>([]);
    const [alertHistory, setAlertHistory] = useState<any[]>([]);
    const [selectedAlert, setSelectedAlert] = useState<any>(null);
    const [removeAlertLoading, setRemoveAlertLoading] = useState(false);

    const chartRef:any = useRef(null);

    const navigate = useNavigate();

    const getAlerts = async() => {
        const response = await API.getMonitorAlerts();
        setAlerts(response.data);
        if (response.data?.length) {
            setSelectedAlert(response.data[0]);
        } 
    }

    const editAlert = async() => {
        if (!alerts.length) return;
        if (alerts.length === 1) {
            navigate(`/alerts/${alerts[0].guid}`);
        } else if (selectedAlert) {
            const guid = typeof selectedAlert === 'string' ? selectedAlert : selectedAlert.guid;
            navigate(`/alerts/${guid}`);
        }
    }

    const removeAlert = async() => {
        if (!alerts.length) return;
        setRemoveAlertLoading(true);
        if (alerts.length === 1) {
            await API.removeAlert(alerts[0].guid);
        } else if (selectedAlert) {
            const guid = typeof selectedAlert === 'string' ? selectedAlert : selectedAlert.guid;
            await API.removeAlert(guid);
        }
        await getAlerts();
        setRemoveAlertLoading(false);
    }

    const showAlertHistory = async(guid:string) => {
        const response = await API.getAlertHistory(guid);
        setAlertHistory(response.history);
    }

    const getAlertByGuid = (guid:string) => {
        return alerts.find((alert) => alert.guid === guid);
    }

    const sumIndices = (array:any[]):any[] => {
        var soma = 0;
        for (var i = 0; i < array.length; i++) {
            soma += parseFloat(array[i][1]);
            array[i].push(soma.toString());
        }
        return array;
    }

    useEffect(() => {
		getAlerts();
    }, []);

    useEffect(() => {
        if (!selectedAlert) return;
        const guid = typeof selectedAlert === 'string' ? selectedAlert : selectedAlert.guid;
        if (!guid) return;
        showAlertHistory(guid);
    }, [selectedAlert])

      
      const labels = alertHistory.map(item => item[0]);
      
      const dataset1 = {
        label: 'Verba Diária',
        data: alertHistory.map(item => parseFloat(item[1])),
        fill: false,
        backgroundColor: 'blue',
        borderColor: 'blue',
      };
      
      const dataset2 = {
        label: 'Verba Total',
        data: sumIndices(alertHistory).map(item => parseFloat(item[2])),
        fill: false,
        backgroundColor: 'red',
        borderColor: 'red',
      };
      let metaValue = 0;
      try {
        const json = JSON.parse(selectedAlert.data);
        metaValue = parseFloat(json.metrics[0].value);
        
      } catch (e) {}
      

const datasetMeta = {
    label: 'Meta',
    data: Array(labels.length).fill(metaValue), // preenche um array com o valor da meta
    fill: false,
    backgroundColor: 'green',
    borderColor: 'green',
    borderDash: [5, 5], // torna a linha tracejada
};
      
      const chartData = {
        labels: labels,
        datasets: [dataset1, dataset2, datasetMeta],
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
          },
        },
      };

    useEffect(() => {
        const handleResize = () => {
            if (chartRef.current) {
                chartRef.current._doResize();
            }
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <MonitorWrapper>
            <PageTitle>Lista de alertas</PageTitle>
            <div className="card card-custom gutter-b p-10">
            { alerts?.length ? <div>
                <h2>Selecione um alerta para visualizar o seu desempenho</h2>
                <div className="h-200px content-wrapper">
                    <div className="content-column">
                        <select name="" size={2} id="" defaultValue={selectedAlert} onChange={(e:any) => { setSelectedAlert(getAlertByGuid(e.target.value)) }} className="alerts-list">
                            { alerts.map((alert:any) => (
                                <option key={alert.guid} value={alert.guid}>{alert.name}</option>
                            ))}
                        </select>
                        <div>
                            <button className="button right-button btn btn-lg btn-primary me-2" disabled={false} onClick={() => { editAlert() }}>Editar alerta</button>
                            <button className="button right-button btn btn-lg btn-danger" disabled={false} onClick={() => { removeAlert() }}>
                                {!removeAlertLoading && <span className='indicator-label'>Excluir alerta</span>}
                                {removeAlertLoading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        Aguarde por favor...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                            </div>
                        <div className="content-wrapper">
                            
                            
                        </div>
                    </div>
                    
                </div>
                <div className="d-flex justify-content-center">
                    <div className="w-75 chart-wrapper">
                        <Line ref={chartRef} data={chartData} options={options} />
                        
                    </div>
                </div>
            </div> : 
            <div className="justify-content-center text-center d-flex">
                <div>
                    <p>Nenhum alerta criado até o momento</p> 
                    <Link to={"/alerts/new"}>
                        <button className="button right-button btn btn-sm btn-primary" onClick={() => {  }}>Criar Alerta</button>
                    </Link>
                </div>
            </div>
            }
            { /* 
            <div className="alert-description">
                Conforme objetivo definido, a promoção Ca – Compre 50 unid. Ganhe 5% de desconto irá atingir a verba definida em 21/04/23, com um consumo médio diário de R$12.000. Até o fim da campanha o sellout total estimado é de R$783.984,00
            </div>
            */ }
            </div>
        </MonitorWrapper>
    )
}

export default AlertMonitoring;