import React from 'react';
import UserShieldIcon from '../../../components/UserShieldIcon';

type UserRowProps = {
  user: any;
  features: any[];
  permissions: any[];
  onPermissionChange: (featureId: number, userId: number, checked: boolean) => void;
};

export const UserRow = ({ user, features, permissions, onPermissionChange }: UserRowProps) => (
  <tr className="hover:bg-gray-50 transition-colors">
    <td className="px-6 py-4 whitespace-nowrap bg-white sticky left-0">
      <div className="flex items-center gap-3">
        <div className="w-10 h-10 rounded-full overflow-hidden">
          {user.src ? (
            <img src={user.src} alt={user.name} className="w-full h-full object-cover" />
          ) : (
            <UserShieldIcon />
          )}
        </div>
        <span className="text-sm font-medium text-gray-900">{user.name}</span>
      </div>
    </td>
    
    {features.map(feature => (
      <td key={`${feature.id_feature}_${user.guid}`} className="px-6 py-4 text-center">
        <input
          type="checkbox"
          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 transition-colors"
          defaultChecked={permissions.some(p => 
            p.id_feature === feature.id_feature && p.id_user === user.id_user
          )}
          onChange={(e) => onPermissionChange(feature.id_feature, user.guid, e.target.checked)}
        />
      </td>
    ))}
  </tr>
);