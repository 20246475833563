import 'bootstrap-icons/font/bootstrap-icons.css';
/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Modal} from 'react-bootstrap';
import styled from '@emotion/styled';
import React, { FC, useEffect, useRef, useState } from 'react';
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1, ChatInner} from '../../../_metronic/partials'
import BtnClose from './../../components/BtnClose';
import BtnStatusOpen from './../../components/BtnStatusOpen';
import IconStatusSuccess from './../../components/IconStatusSuccess';
import IconSupportAnswer from './../../components/IconSupportAnswer';
import IconPlus from './../../components/IconPlus';
import IconDots from './../../components/IconDots';
import SupportChat from './SupportChat';
import SupportStatus from './SupportStatus';
import SupportFilter from './SupportFilter';
import * as API from '../../api';
import { isSuperAdmin } from '../../modules/auth';
import ModalAddTicket from "./ModalAddTicket";
import { useLocation, useParams } from 'react-router-dom';


interface Props {
    
}

const CustomStyle = styled.div`
	position: relative;
	height: 100%;

    .status-filter {
		margin: auto;
		display:flex;
		align-items:center;
		justify-content:center;
	}

	.user-title {
		margin: auto;
		display:flex;
		align-items:center;
		justify-content:center;
	}

	.status-icon {
		margin-left: auto;
	}
`;

const SuppportColumns = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	height: 90%;

	&.super-admin {
		height: 97%;
	}
`;

const ContentListContainer = styled.div`
	position: relative; 
	display: flex;
	flex-direction: column;
	height: 100%;
`

const ContentList = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 1 1px;
	overflow-y: auto;
`

const Support:React.FC<Props> = (props:Props) => { 
    const [selectedTicket, setSelectedTicket] = useState<any>();
    const [tickets, setTickets] = useState<any[]>([]);
	const [filteredTickets, setFilteredTickets] = useState<any[]>([]);
	const [currentFilter, setCurrentFilter] = useState<any>({status:'', subject:'', company:''});
    const [isLoading, setIsLoading] = useState(true);
	const [addTicketModal, showAddTicketModal] = useState<boolean>(false);

	const { ticket } = useParams();
	const location = useLocation();

	useEffect(() => {
		if (ticket && tickets.length)
		{
			setSelectedTicket(tickets.find((item:any) => item.guid == ticket));
		}
	}, [location])

    const getSupportList = async() => {
        const response:any = await (isSuperAdmin() ? API.getAllSupportList() : API.getSupportList());
		const sortedData = response.data.reverse().sort((a:any, b:any) => {
			if (a.status === 'Aberto' && b.status !== 'Aberto') {
				return -1;
			} else if (a.status !== 'Aberto' && b.status === 'Aberto') {
				return 1;
			} else {
				return 0;
			}
		});
		setTickets(sortedData);
        //setTickets(response.data.reverse());
		setFilteredTickets(sortedData);		
		if (!selectedTicket && response.data.length) 
		{
			console.log(`ticket param`, ticket);
			if (ticket)
			{
				setSelectedTicket(response.data.find((item:any) => item.guid == ticket));
			}
			else
			{
				setSelectedTicket(response.data[0]);
			}
		}		
    }

    const onStatusChange = async(status_guid:string) => {
      await API.updateSupportStatus({status_guid, ticket_guid:selectedTicket.guid});
      await getSupportList();
    }

    const onFilterChange = (data:any) => {
        console.log(data);
		setCurrentFilter(data);
		setFilteredTickets(filterTickets(null as any, data));
    }

	const filterTickets = (arr:any[], filters:any = null) => {
		if (!arr) arr = tickets;
		if (!filters) return arr;
		const statusFilter = filters.status === '' ? arr : arr.filter((ticket) => ticket.status === filters.status);
		const subjectFilter = filters.subject === '' ? statusFilter : statusFilter.filter((ticket) => ticket.subject === filters.subject);
		const companyFilter = filters.company === '' ? subjectFilter : subjectFilter.filter((ticket) => ticket.company_guid === filters.company);
		return companyFilter;
	}

	const formatDate = (dateString:string) => {
		const date = new Date(dateString);
		const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
		const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
		const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		return `${hour}:${minute} ${day}/${month}/${date.getFullYear()}`;
	}

    useEffect(() => {
        getSupportList();
		API.markAllNotificationsAsRead();
    }, []);

	useEffect(() => {
		console.log('Valor atualizado de selectedTicket:', selectedTicket);
	}, [selectedTicket]);

    return (
		<CustomStyle>
			<PageTitle>Suporte ao Cliente</PageTitle>
			<Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-900px' show={addTicketModal}>
				<ModalAddTicket onClose={() => {
					showAddTicketModal(false);
					getSupportList();
				}}/>
			</Modal>
			{ isSuperAdmin() ? <></> : 
			<div className='mb-4 d-flex flex-stack'>
				<div></div>
				<div className='card-toolbar d-flex flex-stack' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Abrir Chamado'>
					<button onClick={() => { showAddTicketModal(true) }} className='btn btn-sm btn-light-primary d-flex'>
						<IconPlus/>
						Abrir Chamado
					</button>
				</div>
			</div> }
			{ !tickets.length ? 
				<div className='card text-center d-flex'>
					<div className="card-body">
						<div>
							<label className='w-75 m-5 p-5'>{ isSuperAdmin() ? 'Nenhum ticket criado até o momento' : 'Se tiver dúvidas, sugestões ou problemas técnicos, crie um chamado e responderemos em breve'}</label>
						</div>
						{ isSuperAdmin() ? <></> :  
						<div className='d-flex justify-content-center'>
							<button onClick={() => { showAddTicketModal(true) }} className='btn btn-sm btn-light-primary d-flex justify-content-center align-center'><IconPlus/>Criar Primeiro Chamado</button>
						</div> }
					</div>
					
				</div> : (	
			<SuppportColumns className={ isSuperAdmin() ? 'super-admin' : ''}>
		
		
			<div className='flex-column flex-lg-row-auto w-200 w-lg-400px w-xl-500px mb-10 mb-lg-0'>
			
				<ContentListContainer className='card card-flush'>
					<div className='card-header pt-7' id='kt_chat_contacts_header '>
						<div><h3><label className="text-center w-100">Lista de Chamados</label></h3></div>
						{ isSuperAdmin() ? 
							<div className='me-n3'>
								<button className='btn btn-sm btn-icon btn-active-light-primary' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end' data-kt-menu-flip='top-end'>
									<IconDots/>
								</button>
								<SupportFilter onChange={ (data:any) => onFilterChange(data) } />
							</div>
						
						: <></> } 
					
					</div>
				
					<ContentList className='card-body pt-5' id='kt_chat_contacts_body'>
						<div className='separator separator-dashed d-none'></div>
						
						{ tickets.length ? filteredTickets.map((ticket:any) => { return (
							<div className={`d-flex flex-stack py-4 ${ticket === selectedTicket ? 'bg-secondary rounded' : ''}`} key={ticket.id_ticket}>
							<div className='d-flex align-items-center'>
							<div className='symbol symbol-45px symbol-circle ps-2'>
								{ ticket.src ? 
									<img alt='Pic' src={ticket.src} /> :
									<span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>{ticket.name.charAt(0).toUpperCase()}</span>
								}
							</div>
			
							<div className='ms-5'>
								<div className='d-flex user-title'>
									<a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary ' onClick={ (e) => { e.preventDefault(); setSelectedTicket(ticket) }}>{ticket.name}</a>
									<label className='ms-4 text-gray-400'>{formatDate(ticket.created_at)}</label>
								</div>
								<div className='text-gray-600'>{ticket.company_name}</div>
								<div className='text-gray-600'>{ticket.email}</div>
								
								<div className='text-gray-400'>{ticket.subject}</div>
								
							</div>
							
							</div>
							<div className='status-icon symbol symbol-30px symbol-circle ps-2'>
								<div className='ms-auto'>
									{ticket.status.toLowerCase() !== 'aberto' ?  <IconStatusSuccess/> : ticket.id_user !== ticket.last_user_id_comment ? <IconSupportAnswer/> : <BtnStatusOpen/>}
								</div>
							</div>
							<div className='d-flex flex-column align-items-end ms-2'>
							
							</div>
						</div>
						)}) : 
						<><label>Nenhum chamado no momento</label></>}
						

						<div className='separator separator-dashed d-none'></div>

					</ContentList>
				</ContentListContainer>
			</div>
			
			{<div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
				<ContentListContainer className='card' id='kt_chat_messenger'>
					<div className='card-header' id='kt_chat_messenger_header'>
						
						<div className='card-title'>
							{ tickets.length ? <></> : <label className='text-center w-100'>Nenhum chamado selecionado</label> }
							<div className='symbol-group symbol-hover'></div>
							<div className='d-flex justify-content-center flex-column me-3'>
							
								<a
								href='#'
								className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
								>
								{ selectedTicket ? selectedTicket.name : ''}
								</a>

								<div className='mb-0 lh-1'>
									<span className='badge badge-success w-10px h-10px me-1'></span>
									<span className='fs-7 fw-bold text-gray-400'>{ selectedTicket ? selectedTicket.description_ticket_status : ''}</span>
								</div>
							</div>
						</div>
						{ isSuperAdmin() && tickets.length ? 
						<div className='card-toolbar'>
							<div className='me-n3'>
								<button
								className='btn btn-sm btn-icon btn-active-light-primary'
								data-kt-menu-trigger='click'
								data-kt-menu-placement='bottom-end'
								data-kt-menu-flip='top-end'
								>
									<IconDots/>
								</button>
								<SupportStatus onChange={ (status:string) => onStatusChange(status) } />
							</div>
						</div> : <></> }
					</div>
					<SupportChat isDrawer={false} ticket={selectedTicket} />
				</ContentListContainer>
			</div>}
		</SuppportColumns>)} 
	</CustomStyle>
)}

export default Support;