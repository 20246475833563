/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import {KTSVG} from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap';
import * as API from '../../api';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import FilterModal from './VendedoresFilterModal';
import DownloadModal from './DownloadModal';
import PreloaderIcon from '../../components/PreloaderIcon';

import BtnHelp from "../../components/BtnHelp";
import ManualModal from "../utils/ManualModal";
import ViewerModal from "../utils/ViewerModal";
import { isSuperAdmin } from '../../modules/auth';
import { ReportType } from '../../types/report-type';

type Props = {
  className: string
}

const Style = styled.div`
    .scroll-container {
        max-height: calc(100vh - 250px);
        overflow-y: auto;
    }

    .react-datepicker__input-container input {
        border: 1px solid var(--kt-input-border-color);
        border-radius: 0.475rem;
    }

    .table-row-bordered thead th {
        background-color: white;
        position: sticky;
        top: 0;
        z-index: 10;
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    }

    .table-row-bordered tbody tr:nth-child(even) {
        background-color: #f6f6f6;
    }

    .table-row-bordered tbody tr:nth-child(odd) {
        background-color: white;
    }

    .table-row-bordered tbody tr:hover {
        background-color: #e2e6ea; /* cor de hover para facilitar a leitura */
    }

    .table-row-bordered td, .table-row-bordered th {
        padding: 0.5rem;
    }

    .table-row-bordered th:first-of-type,
    .table-row-bordered td:first-of-type {
        padding-left: 1rem;
    }

    .card-title .card-label {
        color: #7E8299;
        margin-bottom: 0.5rem;
    }

    .missing {
		svg {
			color: #f35050;
		}		
	}
`;

const CadastroVendedorReport: React.FC<Props> = ({className}) => {

    const [columns, setColumns] = useState<any[]>([]);
    const [filters, setFilters] = useState<any>(null);
    const [downloadURL, setDownloadURL] = useState<any>('');
    const [downloadError, setDownloadError] = useState(false);
    const [data, setData] = useState<any>(null);
    const [counter, setCounter] = useState(0);

    const [filterModal, showFilterModal] = useState<boolean>(true);
    const [downloadModal, showDownloadModal] = useState<boolean>(false);

    const REPLACE_COLUMNS_NAME = [
        ['IDPedido', 'Pedido'],
        ['DataAprovado', 'Data Aprovado'],
    	['DataFaturado', 'Data Faturado'],
        ['DataEntregue', 'Data Entregue'],	
        ['DataCancelado', 'Data Cancelado'],
        ['CNPJLojista', 'CNPJ'],
        ['RazaoSocial', 'Razão Social'],
        ['Industria', 'Indústria'],
        ['IDSku', 'SKU'],
        ['FamiliaProduto', 'Familia Produto'],
        ['GrupoProduto', 'Grupo Produto'],
        ['QuantidadeFaturada', 'Quantidade Faturada'],
        ['PrecoTotalSemDesconto', 'Preço Total Sem Desconto'],
        ['PrecoTotalComDesconto', 'Preço Total Com Desconto'],
        ['ValorFaturado', 'Valor Faturado'],
        ['ValorCorte', 'Valor Corte'],
        ['OrigemPedido', 'Origem Pedido'],
        ['TipoVenda', 'Tipo Venda'],
        ['NomeVendedor', 'Nome Vendedor'],
        ['Data', 'Data Captado'],
        ['UnidadeNegocio', 'Unidade Negócio'],
        ['DataCadastro', 'Data Cadastro'],
        ['DataStatusClienteDistribuidor', 'Data Status Cliente Distribuidor'],
        ['FLBlackListIndustria', 'FL BlackList Industria'],
        ['PoliticaPreco', 'Política Preço'],
        ['StatusLojistaDistribuidor', 'Status'],
        ['CargoVendedor', 'Cargo Vendedor'],
        ['EmailVendedor', 'Email Vendedor'],
        ['CelularVendedor', 'Celular Vendedor'],
        ['DataUltimoAcesso', 'Último Acesso'],
        ['EmailAprovador', 'Email Aprovador'],
    ]

    const status:String = '';
    const startDate:Date = new Date((new Date()).setDate((new Date()).getDate() - 7));
    const endDate:Date = new Date();

    const firstUpdate = useRef(true);
    const [limit, setLimit] = useState(100);

    const [manual, setManual] = useState<any>(null);
    const [manualModal, showManualModal] = useState<boolean>(false);
    const [viewerModal, showViewerModal] = useState<boolean>(false);

    const type:ReportType = 'vendedor_distribuidor';

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        setData(null);
        loadData(filters);
    }, [limit]);

    async function loadData(data:any = null) {
        const response = await API.getReport(data ? data : {status, dtstart:getStringDate(startDate), dtend:getStringDate(endDate)}, type, false, limit)
        setColumns(filterColumnsName(response.columns));
        setData(filterData(response.data));
        setCounter(response.counter);
        showFilterModal(false);
    }

    function convertToBrazilianDate(isoDate:any) {
        if (typeof isoDate !== 'string') {
            return null;
        }
        const [datePart, timePartWithExtras] = isoDate.split('T').length > 1 ? isoDate.split('T') : isoDate.split(' ');
        const [year, month, day] = datePart.split('-');
        const timePart = timePartWithExtras ? timePartWithExtras.split('.')[0].split('Z')[0] : '00:00:00';
        const [hour, minute, second] = timePart.split(':');
        return `${day}/${month}/${year}`;
    }

    function isIsoDateString(value:any) {
        const isoDateRegex = /^\d{4}-\d{2}-\d{2}(?:[ T]\d{2}:\d{2}:\d{2}(?:\.\d{3}Z)?)?$/;
        return isoDateRegex.test(value);
    }

    function filterData(data:any) {
        return data.map((row:any) => {
            const length = row.length;
            for (let a = 0; a < length; a++) {
                if (isIsoDateString(row[a])) row[a] = convertToBrazilianDate(row[a]);
            }
            return row;
        });
    }

    function filterColumnsName(columns:any) {
        if (!columns) return;
        REPLACE_COLUMNS_NAME.map((item) => {
            const length = columns.length;
            for (let a = 0; a < length; a++) {
                if (columns[a] == item[0]) columns[a] = item[1];
            }
        })
        return columns;
    }

    function getStringDate(date:Date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
    
        const dayFormatted = day < 10 ? '0' + day : day.toString();
        const monthFormatted = month < 10 ? '0' + month : month.toString();
    
        return `${year}-${monthFormatted}-${dayFormatted}`;
    }

    function applyFilters(data:any) {
        setFilters(data);
        loadData(data);
    }

    const [jobId, setJobId] = useState<any>(null);

    async function exportXLSX() {
        setDownloadError(false);
        showFilterModal(false);
        showDownloadModal(true);
        if (counter > 2000000) return;
        const response = await API.getReport(filters ? filters : {status, dtstart:getStringDate(startDate), dtend:getStringDate(endDate)}, type, true, limit);
        if (!response || !response.success) {
            setDownloadError(true);
        } else {
            //setDownloadURL(response.link);
            setJobId(response.data);
        }
    }

    function handleHelpClick() {
		if (isSuperAdmin()) {
			showManualModal(true);
		} else {
			showViewerModal(true);
		}
	}

    useEffect(() => {
        if (!manualModal) {
            getManualReport();
        }
    }, [showManualModal])

    async function getManualReport() {
        const response = await API.getManualReport(type);
        if (response && response.data.length) {
            setManual(response.data[0]);
        }
    }

    return (
        <Style>
            <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-500px' show={downloadModal}>
                <DownloadModal url={downloadURL} jobId={jobId} error={downloadError} counter={counter} onClose={() => {
                    showDownloadModal(false);
                    setDownloadURL('');
                }}/>
            </Modal>
            <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-500px' show={filterModal}>
                <FilterModal data={filters} type={type} onChange={(data:any) => {
                    applyFilters(data);
                }} onClose={() => {
                    showFilterModal(false);
                }}/>
            </Modal>
            <Modal id='manual_modal' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-800px' show={manualModal}>
                <ManualModal onClose={() => {
                    showManualModal(false)
                    getManualReport();
                }} type={type} item={manual}/>
            </Modal>
            <Modal id='viewer_modal' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-1000px' show={viewerModal}>
                { manual ? <ViewerModal onClose={() => {showViewerModal(false)}} path={manual.path}/> : <></> }
            </Modal>
            <div className={`card ${className}`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Relatório - Vendedor e Distribuidor</span>
                        
                    </h3>
                    {data && counter ? (
                        <span className='mt-4 mb-1' style={{ color: '#7E8299' }}>Total de resultados: {counter}</span>
                    ) : null}
                    { data ? (<span className='mt-4 mb-1' style={{ color: '#7E8299' }}>Resultados visiveis: {data.length}</span>) :<></> }
                    <div className='card-toolbar'>
                        Limite: 
                        <select className="form-select form-select-sm w-100px form-select-solid ms-5 me-5" defaultValue={limit} onChange={({ target: { value } }) => { setLimit(parseFloat(value)) }}>
                            <option value='100'>100</option>
                            <option value='250'>250</option>
                            <option value='500'>500</option>
                            <option value='1000'>1000</option>
                        </select>
                        <a href='#' className='btn btn-sm btn-light-primary me-4'  onClick={() => showFilterModal(true)}>
                            Filtros
                        </a>
                        <a href='#' className='btn btn-sm btn-light-primary' onClick={() => exportXLSX()}>
                            Exportar XLSX
                        </a>
                        { isSuperAdmin() || manual ? 
                        <div className={`help-button ${manual ? '' : 'missing'}`}>
                            <BtnHelp onClick={() => handleHelpClick()}/>
                        </div> : <></> }
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive scroll-container'>
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            { data ? 
                            <thead>
                                <tr className='fw-bold'>
                                { columns?.map((column, index) => (
                                    <th key={index} className='min-w-150px'>{column}</th>
                                ))}
                                </tr>
                            </thead> : null }
                            <tbody>
                            { data && data.length ? data?.map((row:any, rowIndex:any) => (
                                <tr key={rowIndex}>
                                    { row.map((value:any, valueIndex:any) => (
                                        <td key={valueIndex}>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>{value}</span>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            )) : null }
                            </tbody>
                            
                        </table>
                        { data && !data.length ? 
                        <label className='justify-content-center d-flex mt-10 mb-10'>Nenhum resultado encontrado</label> : null}
                        { !data ? 
                        <div className='justify-content-center text-center mb-5'>
                            <p className='mb-5 mt-5'>Carregando, aguarde um momento</p>
                            <div className='d-flex justify-content-center text-center'>
                                <PreloaderIcon/>
                            </div>
                        </div> : null }
                    </div>
                </div>
                
            </div>
        </Style>
    )
}

export default CadastroVendedorReport;