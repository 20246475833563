import {Navigate, Routes, Route, Outlet} from 'react-router-dom';
import {PageLink, PageTitle} from './../../../_metronic/layout/core'
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {KTSVG, toAbsoluteUrl} from './../../../_metronic/helpers'
import * as API from './../../api';
import {Modal} from 'react-bootstrap';
import UserModal from './modal/UserModal';
import MessageModal from './../utils/MessageModal';
import DeleteUserModal from './DeleteUserModal';
import { getUserData, isSuperAdmin } from '../../modules/auth';
import BtnTrash from "../../components/BtnTrash";
import BtnEdit from "../../components/BtnEdit";
import UserShieldIcon from "../../components/UserShieldIcon";
import Select from 'react-select';

const StyledUsers = styled.div`
    .card {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.15s ease-out;
    }

    .card:hover {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    }

    .table-row-dashed tbody tr {
        transition: background-color 0.15s ease-out;
    }

    .table-row-dashed tbody tr:hover {
        background-color: #f3f3f3;
    }

    .btn-icon {
        transition: transform 0.15s ease-out;
        margin-right: 10px;
    }

    .btn-icon:hover {
        transform: scale(1.1);
    }

    .text-end {
        padding-right: 15px !important;
        
    }

    svg {
        width: 20px;
        opacity: 0.7;
    }

    .superadmin svg {
        width: 100%;
        color: red;
        opacity: 1;
    }
    
    .superadmin, .admin, .user {
        height: 40px;

        svg {
            width: 100%;
            height: 100%;
            opacity: 1;
        }

        img {
            width: 100%;
            height: 100%;
            opacity: 1;
        }
    }

    .superadmin svg {
        color: red;
    }

    .admin svg {
        color: orange;
    }

`;

enum USER_LEVEL {
    SUPERADMIN = 2,
    ADMIN = 1,
    MEMBER = 0
};

function getUserLevel(user:any): USER_LEVEL {
    if (user.is_superadmin) return USER_LEVEL.SUPERADMIN;
    if (user.is_admin) return USER_LEVEL.ADMIN;
    return USER_LEVEL.MEMBER;
}

const UserList:FC = () => {
    const [selectedUser, setSelectedUser] = useState<any>();
    const [users, setUsers] = useState<any[]>([]);
    const [modal, showModal] = useState<boolean>(false);
    const [messageModal, showMessageModal] = useState<boolean>(false);
    const [deleteModal, showDeleteModal] = useState<boolean>(false);
    const [maxUsersReached, setMaxUsersReached] = useState<boolean>(false);

    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [usersData, setUsersData] = useState<any>([]);

    function isUserEditable(user:any) {
        const currentUser = getUserData();
        const currentUserLevel = getUserLevel(currentUser);
        const userLevel = getUserLevel(user);

        // Do not edit the current user
        if (user.guid === currentUser.guid) return false;

        // Super Admin can edit all users
        if (currentUserLevel === USER_LEVEL.SUPERADMIN) return true;

        // Admins can edit Members
        if (currentUserLevel === USER_LEVEL.ADMIN && userLevel === USER_LEVEL.MEMBER) return true;

        return false;
    }

    function onDeleteUserClick(user:any) {
        setSelectedUser(user);
        showDeleteModal(true);
    }

    function onEditUserClick(user:any) {
        setSelectedUser(user);
        showModal(true);
    }

    function onUserCreated() {
        updateData();
        showModal(false);
        setSelectedUser(null);
    }

    function onUserUpdated() {
        updateData();
        showModal(false);
        setSelectedUser(null);
    }

    function updateData() {
        API.listUsers().then((response) => {
            const users = response.data.users.filter((user:any) => {
                if (user.is_superadmin) return false;
                if (user.is_viewer) return false;
                return true;
            });
            setUsers(isSuperAdmin() ? response.data.users : users);
            setMaxUsersReached(response.data.maxUsersReached);

            const data:any[] = [];
            response.data.users.map((item: any) => {
                const obj: { [key: string]: any; value: any; label: any } = {
                    value: item.guid,
                    label: item.name
                };
                for (const key in item) {
                    if (item.hasOwnProperty(key)) {
                        obj[key] = item[key];
                    }
                }
                data.push(obj);
            });
            setUsers(data);
        });
    }

    useEffect(() => {
        if (selectedUsers.length) {
            setUsersData(selectedUsers);
        } else {
            setUsersData(users);
        }
    }, [selectedUsers, users]);

    useEffect(() => {
        updateData(); 
    }, []);

    return <StyledUsers>
        <PageTitle>Administração de Usuário</PageTitle>
        <Modal id='modal_message' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-900px' show={messageModal}>
            <MessageModal title='Função indisponível' description='Já foi cadastrado o limite de usuários máximo para essa empresa. Caso seja necessário mais usuários, entre em contato com nossa equipe.' onClose={() => {
                showMessageModal(false);
            }}/>
        </Modal>
        <Modal id='kt_modal_create_app' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-900px' show={modal}>
            { <UserModal userData={selectedUser} onUserUpdated={() => { onUserUpdated() }} onUserCreated={() => { onUserCreated() }} onClose={() => {
                showModal(false);
                setSelectedUser(null);
            }} /> }
        </Modal>
        <Modal id='kt_modal_delete_app' tabIndex={-1} dialogClassName='modal-dialog modal-dialog-centered mw-900px' show={deleteModal}>
            <DeleteUserModal userData={selectedUser} onClose={() => { 
                showDeleteModal(false);
                setSelectedUser(null);  }} 
                onUserDeleted={() => { 
                    showDeleteModal(false);
                    setSelectedUser(null); 
                    API.listUsers().then((response) => {
                        setUsers(response.data.users);
                        setMaxUsersReached(response.data.maxUsersReached);
                    });
            }}/>
        </Modal>
        <div className='form-group mb-4'>
            <label className='form-label fw-bold me-2 w-200px'>Buscar por Usuários:</label>
            <Select
                isMulti
                options={users}
                value={selectedUsers}
                closeMenuOnSelect={false}
                placeholder="Selecionar usuários"
                onChange={(selected: any) => setSelectedUsers(selected)}
                className="multi-select"
            />
        </div>
        <div className={`card shadow-sm`}>
            <div className='card-header border-0 pt-5'>
                
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{users.length ? users[0].company_name : ''}</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>{users.length ? `${users.length} ${users.length == 1 ? 'Membro' : 'Membros'}` : ''}</span>
                </h3>
                <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Adicionar Usuário'>
                    <button onClick={() => { maxUsersReached ? showMessageModal(true) :showModal(true) }} className='btn btn-sm btn-light-primary'>
                        <div className="d-flex">
                            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                            Adicionar Usuário
                        </div>
                    </button>
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-150px'>Usuário</th>
                                <th className='min-w-140px'>E-mail</th>
                                <th className='min-w-120px'>Status</th>
                                <th className='min-w-120px'>Mailling</th>
                                <th className='min-w-100px text-end'>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>{ usersData.map((user:any) => { return (
                            <tr key={user.guid}>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-45px me-5'>
                                            <div className={user.is_superadmin ? 'superadmin' : user.is_admin ? 'admin' : 'user'}>
                                                { user.src ? 
                                                    <img src={user.src} alt='Foto de perfil' className='' /> :
                                                    <UserShieldIcon/>
                                                }
                                                
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start flex-column'>
                                            <label className='text-dark fw-bold text-hover-primary fs-6'>{user.name}</label>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>{user.is_superadmin ? 'Super Admin' : user.is_admin ? 'Administrador' : user.is_viewer ? 'Visualizador' : 'Membro'}</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>{user.email}</span>
                                </td>
                                <td>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>{user.is_actived ? 'Ativo' : 'Desativado'}</span>
                                </td>
                                <td>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>{user.is_mailling ? 'Sim' : 'Não'}</span>
                                </td>
                                <td>
                                    <div className='d-flex mr-4 justify-content-end flex-shrink-0'>
                                        { !isUserEditable(user) ? <p className="m-4"></p> : <>
                                        <button onClick={() => { onEditUserClick(user)}} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                            <BtnEdit/>
                                        </button>
                                        <button onClick={() => {onDeleteUserClick(user)}} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                                            <BtnTrash/>
                                        </button>
                                        </>}
                                    </div>
                                </td>
                            </tr>
                            )})}</>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </StyledUsers>

}

export default UserList;