import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {HomeWrapper} from '../pages/home/HomeWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Dashboard from '../pages/dashboard/Dashboard'
import Users from '../pages/users/Users'
import CompanyTabs from '../pages/company/CompanyTabs'
import GeneralMenu from '../pages/generalmenu/GeneralMenu'
import { SupportWrapper } from '../pages/support/SupportWrapper'
import NPSList from '../pages/nps/NPSList'
import NPSCreator from '../pages/nps/NPSCreator'
import API from '../pages/api/API'
import PowerBITest from '../pages/PowerBITest';
import AlertCreation from '../pages/alerts/AlertCreation'
import AlertMonitoring from '../pages/alerts/AlertMonitoring'
import PedidosReport from '../pages/reports/PedidosReport'
import PromosReport from '../pages/reports/PromosReport'
import NotaFiscalReport from '../pages/reports/NotaFiscalReport'
import CadastroLojistasReport from '../pages/reports/CadastroLojistasReport'
import CadastroVendedorReport from '../pages/reports/CadastroVendedorReport'
import ApuracaoReport from '../pages/reports/ApuracaoReport'
import SelloutReport from '../pages/reports/SelloutReport'
import HistoricoPrecoEstoqueReport from '../pages/reports/HistoricoPrecoEstoqueReport'
import DistribuidorPrioritarioReport from '../pages/reports/DistribuidorPrioritarioReport'
import UltimoPrecoEstoqueReport from '../pages/reports/UltimoPrecoEstoqueReport'
import LojistasReport from '../pages/reports/LojistasReport'
import CuponsReport from '../pages/reports/CuponsReport'
import LogsScreen from '../pages/logs/LogsScreen'
import ReportPoliticaPreco from '../pages/reports/ReportPoliticaPreco'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/home' />} />
        {/* Pages */}
        <Route path='home' element={<HomeWrapper />} />
        <Route path='/' element={<HomeWrapper />} />
        <Route path=':dashboardId/dashboard' element={<Dashboard />} />
        <Route path=':dashboardId/api' element={<API />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='powerbi' element={<PowerBITest />} />
        <Route path='users' element={<Users />} />
        <Route path='companys' element={<CompanyTabs />} />
        <Route path='nps' element={<NPSList />} />
        <Route path='nps/create' element={<NPSCreator npsId={null} onBack={() => {}} />} />
        <Route path='nps/edit/:id' element={<NPSCreator npsId={null} onBack={() => {}} />} />
        <Route path='generalmenu' element={<GeneralMenu />} />
        <Route path='logs' element={<LogsScreen />} />

        <Route path='alerts/list' element={<AlertMonitoring />} />
        <Route path='alerts/new' element={<AlertCreation />} />
        <Route path='alerts/:alertId' element={<AlertCreation />} />

        <Route path='reports/pedidos' element={<PedidosReport className=''/>} />
        <Route path='reports/promos' element={<PromosReport className=''/>} />
        <Route path='reports/notafiscal' element={<NotaFiscalReport className=''/>} />
        <Route path='reports/cadastro_lojistas' element={<CadastroLojistasReport className=''/>} />
        <Route path='reports/cadastro_vendedores' element={<CadastroVendedorReport className=''/>} />
        <Route path='reports/apuracao' element={<ApuracaoReport className=''/>} />
        <Route path='reports/sellout' element={<SelloutReport className=''/>} />
        <Route path='reports/historico_preco_estoque' element={<HistoricoPrecoEstoqueReport className=''/>} />
        <Route path='reports/distribuidor_prioritario' element={<DistribuidorPrioritarioReport className=''/>} />
        <Route path='reports/ultimo_preco_estoque' element={<UltimoPrecoEstoqueReport className=''/>} />
        <Route path='reports/lojistas' element={<LojistasReport className=''/>} />
        <Route path='reports/cupons' element={<CuponsReport className=''/>} />
        <Route path='reports/politica_preco' element={<ReportPoliticaPreco className=''/>} />
        
        <Route path='support' element={<SupportWrapper />} />
        <Route path='support/:ticket' element={<SupportWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
