/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import GridLayout from "react-grid-layout";
import styled from '@emotion/styled';
import Support from './Support';

const StyledGridLayout = styled(GridLayout)`
    background-color: white;

    .closebutton {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

interface Props {
  
}

const SupportWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle>Suporte ao Cliente</PageTitle>
      <Support/>
    </>
  )
}

export {SupportWrapper}
