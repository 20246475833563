/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'

const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      <div className={`${classes.footerContainer} d-flex flex-column  align-items-center justify-content-between`}>
        <a href='https://www.compra-agora.com/' className='text-gray-800 text-hover-primary justify-content-center'>
            Powered by Compra Agora
          </a>
        

      </div>
    </div>
  )
}

export {Footer}
