import React from 'react';
import { Loader2, Save } from 'lucide-react';
import clsx from 'clsx';

type UpdateButtonProps = {
  loading: boolean;
  disabled: boolean;
  onClick: () => void;
};

export const UpdateButton = ({ loading, disabled, onClick }: UpdateButtonProps) => (
  <div className="flex justify-end mt-6">
    <button
      className={clsx(
        "px-6 py-2.5 rounded-lg font-medium transition-colors",
        "inline-flex items-center gap-2",
        disabled
          ? "bg-gray-100 text-gray-400 cursor-not-allowed"
          : "bg-blue-600 text-white hover:bg-blue-700"
      )}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? (
        <>
          <Loader2 className="w-5 h-5 animate-spin" />
          <span>Salvando...</span>
        </>
      ) : (
        <>
          <Save className="w-5 h-5" />
          <span>Salvar Alterações</span>
        </>
      )}
    </button>
  </div>
);