import { FC, useState, useEffect } from 'react';
import BtnClose from '../../components/BtnClose';
import styled from '@emotion/styled';
import * as API from '../../api';
import Select from 'react-select';
import ReportFilter from './ReportFilter';
import { useDistributors, useRegions, useIndustries } from '../../../app/modules/auth';

const Style = styled.div`

  .modal-container {
    width: 100%;
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select{
    width: 100%;
    margin-top: 1rem;
  }

  .radio-item {
    margin-right: 30px;
    
    label {
        margin-right: 5px;
    }
`;

type Props = {
    onChange: Function,
    onClose: Function,
    data: any,
    type: string
}

const DistribuidorPrioritarioModal: FC<Props> = (props) => {
    const distributors = useDistributors();
    const industries = useIndustries();
    const regions = useRegions();

    const [loading, setLoading] = useState(false);

    const [businessUnits, setBusinessUnits] = useState<any[]>([]);
    
    const [selectedDistributors, setSelectedDistributors] = useState<any[]>([]);
    const [selectedIndustries, setSelectedIndustries] = useState<any[]>([]);
    const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
    const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<any[]>([]);
    const [selectedUnidadesNegocio, setSelectedUnidadesNegocio] = useState<any[]>([]);

    const [cepInicial, setCepInicial] = useState<string>('');
    const [cepFinal, setCepFinal] = useState<string>('');
    const [segmento, setSegmento] = useState<string>('');
    const [UF, setUF] = useState<string>('');
    const [municipio, setMunicipio] = useState<string>('');
    
    const [filter, setFilter] = useState<any>(null);

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (filter || props.data) {
            const data = filter?.data || props.data;
            if (data.distributors) setSelectedDistributors(data.distributors);
            if (data.industries) setSelectedIndustries(data.industries);
            if (data.regions) setSelectedRegions(data.regions);
            if (data.bu) setSelectedBusinessUnits(data.bu);
            if (data.unidadesNegocios) setSelectedUnidadesNegocio(data.unidadesNegocios);
            if (data.cepInicial) setCepInicial(data.cepInicial);
            if (data.cepFinal) setCepFinal(data.cepFinal);
            if (data.segmento) setSegmento(data.segmento);
            if (data.UF) setUF(data.UF);
            if (data.municipio) setMunicipio(data.municipio);
        }
    }, [props.data, filter]);

    function getData() {
        return {
            distributors:selectedDistributors,
            industries:selectedIndustries,
            regions:selectedRegions,
            unidadesNegocios:selectedUnidadesNegocio,
            cepInicial,
            cepFinal,
            segmento,
            UF,
            municipio,
            bu: selectedBusinessUnits
        }
    }

    async function getBusinessUnits() {
        const result = await API.getBusinessUnits();
        if (result.success) {
            const data:any[] = [];
            result.data.map((item:any) => {
                data.push({
                    value:item[0],
                    label:item[0]
                })
            })
            setBusinessUnits(data);
        }
    }

    function getStringDate(date:Date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
    
        const dayFormatted = day < 10 ? '0' + day : day.toString();
        const monthFormatted = month < 10 ? '0' + month : month.toString();
    
        return `${year}-${monthFormatted}-${dayFormatted}`;
    }

    useEffect(() => {
        getBusinessUnits();
    }, []);

    return (
        <Style>
            <div className='modal-container'>
                
                <div className='px-7 py-5 d-flex justify-content-between'>
                    <div className='pt-2 fs-3 text-dark fw-bolder'>Filtros para o relatório</div>
                    <BtnClose onClick={() => props.onClose()} />    
                </div>
                <ReportFilter name={props.type} filter={getData()} setFilter={setFilter}></ReportFilter>
                <div className='separator border-gray-200'></div>
                <div className='px-7 py-5'>
                    <div className='mb-4 d-flex flex-wrap'>
                        <div className=' justify-content-between modal-container'>

                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Distribuidor:</label>
                                <Select
                                    isMulti
                                    isDisabled={loading}
                                    options={distributors}
                                    value={selectedDistributors}
                                    closeMenuOnSelect={false}
                                    placeholder={'Filtrar por Distribuidor'}
                                    onChange={(selected: any) => setSelectedDistributors(selected)}
                                    className="multi-select"
                                />
                            </div>

                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Indústria:</label>
                                <Select
                                    isMulti
                                    isDisabled={loading}
                                    options={industries}
                                    value={selectedIndustries}
                                    closeMenuOnSelect={false}
                                    placeholder={'Filtrar por Indústria'}
                                    onChange={(selected: any) => setSelectedIndustries(selected)}
                                    className="multi-select"
                                />
                            </div>

                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Regional:</label>
                                <Select
                                    isMulti
                                    isDisabled={loading}
                                    options={regions}
                                    value={selectedRegions}
                                    closeMenuOnSelect={false}
                                    placeholder={'Filtrar por Regional'}
                                    onChange={(selected: any) => setSelectedRegions(selected)}
                                    className="multi-select"
                                />
                            </div>

                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Unidade de Negócio:</label>
                                <Select
                                    isMulti
                                    isDisabled={loading}
                                    options={businessUnits}
                                    value={selectedBusinessUnits}
                                    closeMenuOnSelect={false}
                                    placeholder={'Filtrar por Unidade de Negócio'}
                                    onChange={(selected: any) => setSelectedBusinessUnits(selected)}
                                    className="multi-select"
                                />
                            </div>
                            
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Cep Inicial:</label>
                                <input className="form-input form-control" type='text' disabled={loading} defaultValue={cepInicial} onChange={(e) => {
                                    setCepInicial(e.target.value);
                                }}></input>
                            </div>
                            
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Cep Final:</label>
                                <input className="form-input form-control" type='text' disabled={loading} defaultValue={cepFinal} onChange={(e) => {
                                    setCepFinal(e.target.value);
                                }}></input>
                            </div>

                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Segmento:</label>
                                <input className="form-input form-control" type='text' disabled={loading} defaultValue={segmento} onChange={(e) => {
                                    setSegmento(e.target.value);
                                }}></input>
                            </div>

                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Estado:</label>
                                <input className="form-input form-control" type='text' disabled={loading} defaultValue={UF} onChange={(e) => {
                                    setUF(e.target.value);
                                }}></input>
                            </div>

                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Municipio:</label>
                                <input className="form-input form-control" type='text' disabled={loading} defaultValue={municipio} onChange={(e) => {
                                    setMunicipio(e.target.value);
                                }}></input>
                            </div>
                            
                            <button onClick={() => {
                                setLoading(true);
                                props.onChange(getData());
                            }} className='btn btn-primary filter-btn'>{!loading && 'Filtrar'} {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                    Aguarde...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Style>
    )
}

export default DistribuidorPrioritarioModal;