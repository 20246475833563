import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import BtnClose from '../../components/BtnClose';
import * as API from '../../api';
import { messages } from '../utils/ErrorsText';
import { isSuperAdmin } from '../../modules/auth/core/Auth';
import { getUserData } from '../../modules/auth/core/Auth';
import IconAttachment from './../../components/IconAttachment';
import BtnView from './../../components/BtnStatusOpen';

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const StyledModal = styled.div`
    .card-header {
        align-items: center;
    }

    .button-view {
        position: relative;
        top: 8px;
        cursor: pointer;
    }

    .pdfViewer {
        object {
            width: 100%;
            height: 60vh;
            min-height: 400px;
        }

        embed {
            width: 100%;
            height: 60vh;
            min-height: 400px;
        }
    }

    .terms-check {
        display: flex;
        padding-left: 30px;
        justify-content: center;
        
        label {
            padding-left: 10px;
        }
    }

    .card-header {
        margin-bottom: 0px !important;
    }

    .terms-title {
        padding-bottom: 20px;
    }

    .button-confirm {
        width: 200px;
    }
`;

interface Props {
    userData?: any,
    onClose: Function,
    hideClose?: boolean,
    path: string,
    isUpdate?: boolean
}

const GuidelineViewerModal:FC<Props> = (props:Props) => {
    const [checkbox, setCheckbox] = useState(false);
    const [loading, setLoading] = useState(false)

    const newTitle = 'Para continuar utilizando nossos serviços, é necessário que você esteja de acordo com nossos Termos de Uso. Por favor, leia com atenção e aceite para prosseguir';
    const updateTitle = 'Nossos Termos de Uso foram atualizados. Por favor, leia os novos termos e aceite para continuar a usar nossos serviços';

    const getExtension = () => {
        const parts = props.path.split('.');
        return parts[parts.length - 1];
    }

    const isImage = () => {
        const extension = getExtension();
        return extension === 'jpg' || extension === 'jpeg' || extension === 'png';
    }

    const isPDF = () => {
        const extension = getExtension();
        return extension === 'pdf';
    }

    const confirmGuidelines = async() => {
        setLoading(true);
        await API.acceptGuidelines();
        window.location.reload();
    }

    const onCheckChange = (value:any) => {
        setCheckbox(value);
    }

    return (
    <StyledModal className="card mb-10">
        <div className='card-header border-0 mb-0'>
            <h3>Termos de Uso</h3>
        </div>
        <div className='card-body mb-5 mb-xl-10 pt-0'>
        <label className='terms-title justify-content-center d-flex'>{ getUserData().old_guidelines ? updateTitle : newTitle}</label>
            { isPDF() ? (  
            <div className='pdfViewer'>
                <object data={props.path} type="application/pdf">
                    <embed src={props.path} type="application/pdf"></embed>
                </object>
            </div>) : (
            <div>
                <img alt='Imagem' className='mh-500px mw-500px' src={props.path} />
            </div> )}
        </div>
        <div className='terms-check'>
            <div className='form-check form-check-sm form-check-custom d-flex justify-content-center text-center'>
                <input className='form-check-input border-dark'type='checkbox' defaultChecked={checkbox} onChange={(evt) => { onCheckChange(evt.target.checked) }}></input>
            </div>
            <label>Confirmo que li e estou de acordo com os Termos de Uso.</label>
        </div>
        <div className='d-flex justify-content-center'>
            <button onClick={() => {
                confirmGuidelines();
            }} disabled={!checkbox || loading} className='btn btn-primary mt-4'>{!loading && 'Confirmar'} {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                    Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                )}
            </button>
        </div>
    </StyledModal>
    )
}

export default GuidelineViewerModal