import React from 'react';
import { Star, SmilePlus, Meh, Frown, Smile, StarOff, ThumbsUp, ThumbsDown, Check, X, Heart, BarChart, Angry } from 'lucide-react';

interface SurveyQuestionProps {
  title: string;
  description?: string;
  type: 'text' | 'number' | 'emoticon' | 'stars' | 'thumbs' | 'yesno' | 'hearts' | 'slider' | 'choice' | 'rating';
  value: any;
  onChange: (value: any) => void;
  options?: string[];
}

export default function SurveyQuestion({ 
  title, 
  description, 
  type, 
  value, 
  onChange,
  options = []
}: SurveyQuestionProps) {
  const renderNumberOptions = () => {
    return (
      <div className="flex flex-wrap justify-center gap-3 max-w-2xl mx-auto">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
          <button
            key={num}
            onClick={() => onChange(num)}
            className={`w-14 h-14 rounded-xl flex items-center justify-center text-lg font-semibold
              transition-all duration-200 ${
              value === num
                ? 'bg-blue-500 text-white scale-110'
                : 'bg-white text-gray-700 hover:bg-gray-50'
              } border shadow-sm`}
          >
            {num}
          </button>
        ))}
      </div>
    );
  };

  const renderEmoticons = () => {
    const emotions = [
      { icon: Angry, label: 'Muito Insatisfeito', color: 'text-red-500' },
      { icon: Frown, label: 'Insatisfeito', color: 'text-orange-500' },
      { icon: Meh, label: 'Neutro', color: 'text-yellow-500' },
      { icon: Smile, label: 'Satisfeito', color: 'text-green-500' },
      { icon: SmilePlus, label: 'Muito Satisfeito', color: 'text-emerald-500' }
    ];

    return (
      <div className="flex justify-center gap-6 max-w-3xl mx-auto">
        {emotions.map((emotion, index) => {
          const Icon = emotion.icon;
          return (
            <button
              key={index}
              onClick={() => onChange(index + 1)}
              className={`flex flex-col items-center gap-2 p-4 rounded-xl transition-all duration-200
                ${value === index + 1 ? 'scale-110' : 'hover:scale-105'}`}
            >
              <Icon 
                className={`w-12 h-12 ${value === index + 1 ? emotion.color : 'text-gray-400'}`}
              />
              <span className={`text-sm font-medium ${value === index + 1 ? emotion.color : 'text-gray-500'}`}>
                {emotion.label}
              </span>
            </button>
          );
        })}
      </div>
    );
  };

  const renderStars = () => {
    return (
      <div className="flex justify-center gap-2">
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            key={star}
            onClick={() => onChange(star)}
            className="transition-transform hover:scale-110"
          >
            {star <= value ? (
              <Star className="w-10 h-10 text-yellow-400 fill-yellow-400" />
            ) : (
              <StarOff className="w-10 h-10 text-gray-300" />
            )}
          </button>
        ))}
      </div>
    );
  };

  const renderThumbs = () => {
    return (
      <div className="flex justify-center gap-12">
        <button
          onClick={() => onChange('down')}
          className={`p-6 rounded-full transition-all duration-200
            ${value === 'down' ? 'bg-red-100 scale-110' : 'hover:bg-gray-100'}`}
        >
          <ThumbsDown className={`w-12 h-12 ${value === 'down' ? 'text-red-500' : 'text-gray-400'}`} />
        </button>
        <button
          onClick={() => onChange('up')}
          className={`p-6 rounded-full transition-all duration-200
            ${value === 'up' ? 'bg-green-100 scale-110' : 'hover:bg-gray-100'}`}
        >
          <ThumbsUp className={`w-12 h-12 ${value === 'up' ? 'text-green-500' : 'text-gray-400'}`} />
        </button>
      </div>
    );
  };

  const renderYesNo = () => {
    return (
      <div className="flex justify-center gap-6">
        <button
          onClick={() => onChange(false)}
          className={`flex items-center gap-2 px-8 py-4 rounded-xl transition-all duration-200
            ${value === false ? 'bg-red-100 scale-105' : 'bg-white hover:bg-gray-50'} border`}
        >
          <X className={`w-6 h-6 ${value === false ? 'text-red-500' : 'text-gray-400'}`} />
          <span className={`font-medium ${value === false ? 'text-red-500' : 'text-gray-700'}`}>Não</span>
        </button>
        <button
          onClick={() => onChange(true)}
          className={`flex items-center gap-2 px-8 py-4 rounded-xl transition-all duration-200
            ${value === true ? 'bg-green-100 scale-105' : 'bg-white hover:bg-gray-50'} border`}
        >
          <Check className={`w-6 h-6 ${value === true ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={`font-medium ${value === true ? 'text-green-500' : 'text-gray-700'}`}>Sim</span>
        </button>
      </div>
    );
  };

  const renderHearts = () => {
    return (
      <div className="flex justify-center gap-2">
        {[1, 2, 3, 4, 5].map((heart) => (
          <button
            key={heart}
            onClick={() => onChange(heart)}
            className="transition-transform hover:scale-110"
          >
            <Heart 
              className={`w-10 h-10 ${heart <= value 
                ? 'text-red-500 fill-red-500' 
                : 'text-gray-300'}`}
            />
          </button>
        ))}
      </div>
    );
  };

  const renderSlider = () => {
    return (
      <div className="w-full max-w-md mx-auto">
        <input
          type="range"
          min="0"
          max="100"
          value={value || 0}
          onChange={(e) => onChange(Number(e.target.value))}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        />
        <div className="text-center mt-4">
          <span className="text-2xl font-bold text-blue-600">{value || 0}%</span>
        </div>
      </div>
    );
  };

  const renderChoice = () => {
    console.log(options);
    return (
        
      <div className="flex flex-col gap-3 max-w-md mx-auto">
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => onChange(option)}
            className={`p-4 rounded-xl transition-all duration-200
              ${value === option
                ? 'bg-blue-50 border-blue-200 scale-105'
                : 'bg-white hover:bg-gray-50'
              } border`}
          >
            <span className={`font-medium ${value === option ? 'text-blue-600' : 'text-gray-700'}`}>
              {option}
            </span>
          </button>
        ))}
      </div>
    );
  };

  const renderRating = () => {
    const ratings = [
      { label: 'Ruim', color: 'text-red-500' },
      { label: 'Regular', color: 'text-orange-500' },
      { label: 'Bom', color: 'text-yellow-500' },
      { label: 'Muito Bom', color: 'text-lime-500' },
      { label: 'Excelente', color: 'text-green-500' }
    ];

    return (
      <div className="flex flex-col gap-3 max-w-md mx-auto">
        {ratings.map((rating, index) => (
          <button
            key={index}
            onClick={() => onChange(index + 1)}
            className={`flex items-center justify-between p-4 rounded-xl transition-all duration-200
              ${value === index + 1
                ? `bg-${rating.color.split('-')[1]}-50 scale-105`
                : 'bg-white hover:bg-gray-50'
              } border`}
          >
            <span className={`font-medium ${value === index + 1 ? rating.color : 'text-gray-700'}`}>
              {rating.label}
            </span>
            <BarChart className={`w-5 h-5 ${value === index + 1 ? rating.color : 'text-gray-400'}`} />
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="p-8 bg-white rounded-2xl shadow-sm hover:shadow-md transition-shadow duration-200">
      <div className="text-center mb-6">
        <h3 className="text-xl font-bold text-gray-800 mb-2">{title}</h3>
        {description && (
          <p className="text-gray-600">{description}</p>
        )}
      </div>
      
      <div className="mt-8">
        {type === 'text' && (
          <textarea
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="w-full p-4 border border-gray-200 rounded-xl focus:ring-2 
                     focus:ring-blue-500 focus:border-transparent transition-all
                     duration-200 min-h-[120px] text-gray-700 placeholder-gray-400"
            placeholder="Compartilhe sua opinião conosco..."
          />
        )}
        {type === 'number' && renderNumberOptions()}
        {type === 'emoticon' && renderEmoticons()}
        {type === 'stars' && renderStars()}
        {type === 'thumbs' && renderThumbs()}
        {type === 'yesno' && renderYesNo()}
        {type === 'hearts' && renderHearts()}
        {type === 'slider' && renderSlider()}
        {type === 'choice' && renderChoice()}
        {type === 'rating' && renderRating()}
      </div>
    </div>
  );
}