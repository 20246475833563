import React from 'react';

type CompanyFilterProps = {
  selectedFilter: string;
  onFilterChange: (value: string) => void;
};

export const CompanyFilter = ({ selectedFilter, onFilterChange }: CompanyFilterProps) => (
  <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
    <div className="flex items-center gap-8">
      <span className="text-sm font-medium text-gray-700">Tipo de Empresa:</span>
      
      <label className="flex items-center gap-2">
        <input
          type="radio"
          value="Todos"
          checked={selectedFilter === 'Todos'}
          onChange={(e) => onFilterChange(e.target.value)}
          className="rounded-full border-gray-300 text-blue-600 focus:ring-blue-500"
        />
        <span className="text-sm text-gray-600">Todos</span>
      </label>

      <label className="flex items-center gap-2">
        <input
          type="radio"
          value="1"
          checked={selectedFilter === '1'}
          onChange={(e) => onFilterChange(e.target.value)}
          className="rounded-full border-gray-300 text-blue-600 focus:ring-blue-500"
        />
        <span className="text-sm text-gray-600">Indústrias</span>
      </label>

      <label className="flex items-center gap-2">
        <input
          type="radio"
          value="2"
          checked={selectedFilter === '2'}
          onChange={(e) => onFilterChange(e.target.value)}
          className="rounded-full border-gray-300 text-blue-600 focus:ring-blue-500"
        />
        <span className="text-sm text-gray-600">Distribuidores</span>
      </label>
    </div>
  </div>
);