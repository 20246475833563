/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth, getUserData, isAdmin, isSuperAdmin} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import { Props } from '../InboxCompose'
import { NotificationType } from '../../../../app/pages/utils/Types'
import clsx from 'clsx'
import IconPlus from '../../../../app/components/IconPlus'
import styled from '@emotion/styled'

const NotificationMenu = styled.div`
    max-height: 40vh;
    overflow: auto;
    width: 400px !important;

    .notification-description {
        max-width: 280px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: inherit;
    }
`

const NotificationItem = styled.div<{ $action?: string }>`
    position: relative;
    display: flex;
    align-items: center;
    height: 100px;
    padding: 5px 10px;
`

const HeaderNotificationMenu: FC<{ notificationsList: Array<NotificationType>, onNotificationClicked?: Function}> = ({notificationsList, onNotificationClicked}) => {
  const {currentUser, logout} = useAuth();

  return (
    <NotificationMenu
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-350px mh-300'
      data-kt-menu='true'
    >
        <label className='text-center pt-2 pb-4 '>Últimas notificações:</label>
        { 
        notificationsList.length ?
            notificationsList.map(item => {
            return (
                <NotificationItem key={item.url + "_" + Math.random()} className='menu-item' $action={item.action}>
                    <Link to={ item.url ? item.url : '/support'} className='d-flex justify-content-between align-items-center menu-link' onClick={() => { if (onNotificationClicked) onNotificationClicked(item) }}>
                      <div className='symbol symbol-35px me-5 symbol-circle'>
                            { item.path ? 
                                <img alt='Logo' src={ item.path } />
                            :
                                <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>{ item.name.charAt(0).toUpperCase() }</span>
                            }
                      </div>
                      <div className='d-flex flex-column flex-grow-1'>
                          <div className='fw-bolder d-flex align-items-center fs-5'>
                              { item.name }
                          </div>
                          <div className='fw-bold text-muted fs-7 notification-description'>
                              { item.description }
                          </div>
                      </div>
                      { item.action == 'unread' ? <span className='bullet bullet-dot bg-success h-6px w-6px position-relative'></span> : ''}
                    </Link>
                </NotificationItem>
            )
        })
        :
            <div className={clsx('menu-item', 'px-5')}>
                <Link to={ '/support' } className='btn btn-sm btn-light-primary d-flex'>
                    <IconPlus/>
                    Abrir Chamado
                </Link>
            </div>
        }
    </NotificationMenu>
  )
}

export {HeaderNotificationMenu}
