import styled from '@emotion/styled';
import { useState } from 'react'
import { InputAddMenuItemProps } from './AddMenuItemModels'

const StyledActionButtons = styled.div`
    display: flex;
    gap:8px;
`;

const StyledRow = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;

const InputAddMenuItem = ({typeData, onSavingItem }: InputAddMenuItemProps) => {
  const [newItem, setNewItem] = useState<string>('');

  const showButtons = () => {
    return (
        <StyledActionButtons>
          <button 
            type='button'
            className='btn btn-primary'
            onClick={() => { 
              if (newItem === undefined || !newItem.length || newItem === '') {
                //TODO exibir erro do input text
                console.log('Por favor, preencha o campo');
                return
              }

              onSavingItem(newItem);
              setNewItem('');
            }}>Salvar</button>
        </StyledActionButtons>
    );
    }

  const showInputText = () => {
    return (
      <>
        <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={typeData?.inputName}
            placeholder={typeData?.inputPlaceholder}
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
          />
      </>
    );
  }

  const showInputAddItemView = () => {
    return (
      <StyledRow>
        
            <label className='col-lg-2 col-form-label required fw-bold fs-6'>{typeData?.label}</label>
            <div className='col-lg-8 fv-row'>
              {showInputText()}
            </div>
            <div className='col-lg-8 fv-row'>
              {showButtons()}
            </div>
        
      </StyledRow>
    );
  }

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='fv-row mb-10'>
          {showInputAddItemView()}
        </div>
      </div>
    </div>
  )
}

export {InputAddMenuItem}
