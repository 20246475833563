import React from 'react';
import { X, Plus } from 'lucide-react';
import clsx from 'clsx';

type DomainInputProps = {
  domains: string[];
  onAdd: () => void;
  onRemove: (index: number) => void;
  onChange: (value: string, index: number) => void;
};

export const DomainInput = ({ domains, onAdd, onRemove, onChange }: DomainInputProps) => (
  <div className="space-y-4">
    <div className="flex items-center justify-between">
      <label className="block text-sm font-medium text-gray-700">Domínios:</label>
      <button
        type="button"
        onClick={onAdd}
        className="inline-flex items-center px-3 py-1 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-lg transition-colors"
      >
        <Plus className="w-4 h-4 mr-1" />
        Adicionar Domínio
      </button>
    </div>

    {domains.length === 0 ? (
      <p className="text-sm text-gray-500 italic">Nenhum domínio configurado</p>
    ) : (
      <div className="space-y-3">
        {domains.map((domain, index) => (
          <div key={index} className="flex gap-2">
            <input
              type="text"
              className={clsx(
                "flex-1 px-4 py-2 rounded-lg border focus:ring-2 focus:ring-blue-500",
                "transition-colors duration-200",
                domain.length > 0 && !/^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/.test(domain)
                  ? "border-red-300 bg-red-50"
                  : "border-gray-200"
              )}
              placeholder="Exemplo: nomedaempresa.com.br"
              value={domain}
              onChange={(e) => onChange(e.target.value, index)}
            />
            <button
              type="button"
              onClick={() => onRemove(index)}
              className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-lg transition-colors"
              title="Remover domínio"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        ))}
      </div>
    )}
  </div>
);