import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.div`
    &:hover {
        i,
        .svg-icon {
            opacity: 0.3
        }
    }
`;

interface Props {
    onClick?: Function
}

const BtnFile:React.FC<Props> = (props:Props) => {
    return <StyledButton>
        <div onClick={() => props.onClick && props.onClick()}>
            <div className="btn btn-sm btn-icon btn-active-color-primary">
                <span className="svg-icon svg-icon-1">
                <svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 30.016q0 0.832 0.576 1.408t1.44 0.576h20q0.8 0 1.408-0.576t0.576-1.408v-22.016l-8-8h-13.984q-0.832 0-1.44 0.608t-0.576 1.408v28zM8 28v-24h10.016v6.016h5.984v17.984h-16z"></path>
                </svg>
                </span>
            </div>
        </div>
    </StyledButton>
}

export default BtnFile;

