import { FC, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import BtnClose from '../../components/BtnClose';
import styled from '@emotion/styled';
import * as API from '../../api';
import Select from 'react-select';
import ReportFilter from './ReportFilter';
import { useDistributors, useIndustries } from '../../modules/auth';
import pt from 'date-fns/locale/pt';

const Style = styled.div`

  .modal-container {
    width: 100%;
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select{
    width: 100%;
    margin-top: 1rem;
  }

  .mailling-item {
    margin-right: 30px;
    
    label {
        margin-right: 10px;
    }
}

.radio-item {
    margin-right: 30px;
    
    label {
        margin-right: 5px;
    }

    .opacity-test {
    
        background-color: red;
    }
`;

type Props = {
    onChange: Function,
    onClose: Function,
    data: any,
    type: string
}

const LojistasFilterModal: FC<Props> = (props) => {
    const distributors = useDistributors();
    const industries = useIndustries();

    const [loading, setLoading] = useState(false);

    const [selectedDistributors, setSelectedDistributors] = useState<any[]>([]);
    const [selectedIndustries, setSelectedIndustries] = useState<any[]>([]);

    const [nomeCupom, setNomeCupom] = useState<string>('');
    const [idRegraPromocao, setIDRegraPromocao] = useState<string>('');
    const [cupomUtilizado, setCupomUtilizado] = useState<string>('');
    const [cnpj, setCNPJ] = useState<string>('');
    const [name, setName] = useState<string>('');

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());

    const currentYear = new Date().getFullYear(); // Obtém o ano atual
    const maxDate = new Date(currentYear, 11, 31); // Dezembro é o mês 11, já que os meses são 0-indexados
    const minDate = new Date("2020/01/01");

    const [filter, setFilter] = useState<any>(null);
    
    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        clear();
        if (filter || props.data) {
            const data = filter?.data || props.data;
            if (data.nomeCupom) setNomeCupom(data.nomeCupom);
            if (data.regraPromocao) setIDRegraPromocao(data.regraPromocao);
            if (data.cupomUtilizado) setCupomUtilizado(data.cupomUtilizado);
            if (data.dtstart) setStartDate(new Date(new Date(data.dtstart).setDate(new Date(data.dtstart).getDate() + 1)));
            if (data.dtend) setEndDate(new Date(new Date(data.dtend).setDate(new Date(data.dtend).getDate() + 1)));
            if (data.cnpj) setCNPJ(data.cnpj);
            if (data.name) setName(data.name);
        }
    }, [props.data, filter]);

    function clear() {
        setSelectedDistributors([]);
        setSelectedIndustries([]);
        setStartDate(new Date());
        setEndDate(new Date());
        setCNPJ('');
        setName('');
    }

    function getData() {
        return {
            nomeCupom,
            regraPromocao:idRegraPromocao,
            cupomUtilizado,
            dtstart:getStringDate(getFirstDayOfMonth(startDate)),
            dtend:getStringDate(getLastDayOfMonth(endDate)),
            cnpj,
            name,
            
        }
    }

    function getFirstDayOfMonth(date: Date): Date {
        const year = date.getFullYear();
        const month = date.getMonth();
        // Define a data para o primeiro dia do mês atual
        return new Date(year, month, 1);
    }

    function getLastDayOfMonth(date: Date): Date {
        const year = date.getFullYear();
        const month = date.getMonth();
        // Define a data para o primeiro dia do mês seguinte, depois subtrai um dia, obtendo assim o último dia do mês atual
        return new Date(year, month + 1, 0);
    }

    function getStringDate(date:Date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
    
        const dayFormatted = day < 10 ? '0' + day : day.toString();
        const monthFormatted = month < 10 ? '0' + month : month.toString();
    
        return `${year}-${monthFormatted}-${dayFormatted}`;
    }

    return (
        <Style>
            <div className='modal-container'>
                
                <div className='px-7 py-5 d-flex justify-content-between'>
                    <div className='pt-2 fs-3 text-dark fw-bolder'>Filtros para o relatório</div>
                    <BtnClose onClick={() => props.onClose()} />    
                </div>
                <ReportFilter name={props.type} filter={getData()} setFilter={setFilter}></ReportFilter>
                <div className='separator border-gray-200'></div>
                <div className='px-7 py-5'>
                    <div className='mb-4 d-flex flex-wrap'>
                        <div className=' justify-content-between modal-container'>
                            { cupomUtilizado != '0' ? <>
                            <div className='form-group'>
                                <label className='form-label fw-bold w-150px'>Inicio:</label>
                                <DatePicker locale={pt} showMonthYearPicker disabled={loading} dateFormat={'MM/yyyy'} selected={startDate} onChange={(date:any) => { setStartDate(date) }} className="form-input form-control" minDate={minDate} maxDate={maxDate}/>
                            </div>
                            
                            <div className='form-group'>
                                <label className='form-label fw-bold w-150px'>Término:</label>
                                <DatePicker locale={pt} showMonthYearPicker disabled={loading} dateFormat={'MM/yyyy'} selected={endDate} onChange={(date:any) => { setEndDate(date) }} className="form-input form-control" minDate={minDate} maxDate={maxDate}/>
                            </div>
                            </> : null }
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Nome do Cupom:</label>
                                <input className="form-input form-control" type='text' disabled={loading} defaultValue={nomeCupom} onChange={(e) => {
                                    setNomeCupom(e.target.value);
                                }}></input>
                            </div>
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>ID Regra Promoção:</label>
                                <input className="form-input form-control" type='text' disabled={loading} defaultValue={idRegraPromocao} onChange={(e) => {
                                    setIDRegraPromocao(e.target.value);
                                }}></input>
                            </div>
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Razão Social:</label>
                                <input className="form-input form-control" type='text' disabled={loading} value={name} onChange={(e) => {
                                    setName(e.target.value);
                                }}></input>
                            </div>
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>CNPJ:</label>
                                <input className="form-input form-control" type='text' disabled={loading} value={cnpj} onChange={(e) => {
                                    setCNPJ(e.target.value);
                                }}></input>
                            </div>
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Cupom Utilizado</label>
                                <div className='form-control d-flex'>
                                    <div className='radio-item'>
                                        <label>Todos</label>
                                        <input className='form-check-input' type='radio' name='cupomutilizado' value='' checked={ cupomUtilizado == '' } onChange={({ target: { value } }) => { setCupomUtilizado(value) }}/>
                                    </div> 
                                    <div className='radio-item'>
                                        <label>Sim</label>
                                        <input className='form-check-input' type='radio' name='cupomutilizado' value='1' checked={ cupomUtilizado == '1' } onChange={({ target: { value } }) => { setCupomUtilizado(value) }}/>
                                    </div>                                
                                    <div className='radio-item'>
                                        <label>Não</label>
                                        <input className='form-check-input' type='radio' name='cupomutilizado' value='0' checked={ cupomUtilizado == '0' } onChange={({ target: { value } }) => { setCupomUtilizado(value) }}/>
                                    </div>
                                </div>                                
                            </div>
                            
                            <button onClick={() => {
                                setLoading(true);
                                props.onChange(getData());
                            }} className='btn btn-primary filter-btn'>{!loading && 'Filtrar'} {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                    Aguarde...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Style>
    )
}

export default LojistasFilterModal;