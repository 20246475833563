import styled from '@emotion/styled';

export const FilterContainer = styled.div`
  .modal-container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  }

  .modal-header {
    padding: 1.75rem 2.5rem;
    border-bottom: 1px solid #e5e7eb;
    background: linear-gradient(to right, #f8fafc, #f1f5f9);
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal-title {
    font-size: 1.75rem;
    color: #1e293b;
    font-weight: 700;
    letter-spacing: -0.025em;
  }

  .form-section {
    padding: 2rem;
  }

  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: 1600px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .form-group {
    &.full-width {
      grid-column: 1 / -1;
    }

    &.half-width {
      grid-column: span 2;
    }

    &.triple-width {
      grid-column: span 3;
    }
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #374151;
    font-size: 0.875rem;
    letter-spacing: -0.025em;
  }

  .form-control, .form-select {
    width: 100%;
    border: 1.5px solid #e5e7eb;
    border-radius: 10px;
    padding: 0.75rem 1rem;
    transition: all 0.2s ease;
    background-color: #f9fafb;
    font-size: 0.9375rem;
    color: #1f2937;

    &:hover {
      border-color: #d1d5db;
      background-color: #f3f4f6;
    }

    &:focus {
      border-color: #3b82f6;
      box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.12);
      background-color: #fff;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .date-picker-wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;

    .form-select {
      width: 140px;
      flex-shrink: 0;
    }

    .form-control {
      flex: 1;
    }
  }

  .radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0.75rem;
    background: #f8fafc;
    border-radius: 12px;
    border: 1.5px solid #e5e7eb;
  }

  .radio-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    border-radius: 8px;
    transition: all 0.2s ease;
    
    &:hover {
      background: #f1f5f9;
    }
    
    label {
      cursor: pointer;
      color: #4b5563;
      font-weight: 500;
      user-select: none;
    }

    input {
      cursor: pointer;
      width: 1.125rem;
      height: 1.125rem;
      border: 2px solid #d1d5db;
      
      &:checked {
        border-color: #3b82f6;
        background-color: #3b82f6;
      }
    }
  }

  .filter-btn {
    width: 100%;
    max-width: 300px;
    margin: 2rem auto 0;
    padding: 1rem;
    background: linear-gradient(to right, #3b82f6, #2563eb);
    color: white;
    border: none;
    border-radius: 12px;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.025em;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    &:hover:not(:disabled) {
      background: linear-gradient(to right, #2563eb, #1d4ed8);
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
    }

    &:active:not(:disabled) {
      transform: translateY(0);
    }

    &:disabled {
      background: linear-gradient(to right, #93c5fd, #60a5fa);
      cursor: not-allowed;
    }
  }

  .select-container {
    .select__control {
      border: 1.5px solid #e5e7eb;
      border-radius: 10px;
      background-color: #f9fafb;
      min-height: 45px;
      box-shadow: none;

      &:hover {
        border-color: #d1d5db;
        background-color: #f3f4f6;
      }

      &--is-focused {
        border-color: #3b82f6;
        box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.12);
        background-color: #fff;
      }
    }

    .select__multi-value {
      background-color: #eff6ff;
      border-radius: 6px;
      padding: 2px;
      
      &__label {
        color: #1e40af;
        font-weight: 500;
      }

      &__remove {
        color: #1e40af;
        
        &:hover {
          background-color: #dbeafe;
          color: #1e3a8a;
        }
      }
    }

    .select__menu {
      border-radius: 10px;
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
      border: 1.5px solid #e5e7eb;
      overflow: hidden;
    }

    .select__option {
      padding: 0.75rem 1rem;
      
      &--is-focused {
        background-color: #eff6ff;
      }
      
      &--is-selected {
        background-color: #3b82f6;
      }
    }
  }

  .section-divider {
    height: 1px;
    background: #e5e7eb;
    margin: 2rem 0;
    width: 100%;
  }

  .form-section-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid #e5e7eb;
  }

  .form-columns {
    display: flex;
    gap: 2rem;
    margin-bottom: 1.5rem;

    .form-column {
      flex: 1;
      min-width: 0;
    }
  }

  .loading-spinner {
    animation: spin 1s linear infinite;
    margin-left: 0.5rem;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;