import React from 'react';
import { PageTitle } from '../../../_metronic/layout/core';

const APIPage:React.FC = () => {
    return (
        <div>
            <PageTitle breadcrumbs={[]}>API</PageTitle>
        </div>
    );
}

export default APIPage;
