import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import BtnClose from '../../components/BtnClose';
import * as API from '../../api';
import { messages } from '../utils/ErrorsText';
import { isSuperAdmin } from '../../modules/auth/core/Auth';
import { getUserData } from '../../modules/auth/core/Auth';

const StyledModal = styled.div`
    
`;

interface Props {
    userData?: any,
    onClose: Function,
    message: string
}

const ModalAddTicket:FC<Props> = (props:Props) => {
    const [loading, setLoading] = useState(false);
    const [subject, setSubject] = useState<string>('');
    const [description, setDescription] = useState<string>('');
        
    return (
    <StyledModal className="card mb-5">
        <div className='card '>
            <div className='card-body text-center pt-9 pb-0'>
                <p>{props.message}</p>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <button type='submit' id='kt_sign_in_submit' className='btn btn-lg btn-primary w-50 mt-5 bg-success me-5' onClick={() => { props.onClose(true) }}>Sim</button>
                    <button type='submit' id='kt_sign_in_submit' className='btn btn-lg btn-primary w-50 mt-5 bg-danger' onClick={() => { props.onClose(false) }}>Não</button>
                </div>
            
            </div>
        </div>
    </StyledModal>
    )
}

export default ModalAddTicket