import React, { useState, useEffect } from 'react';
import { ChevronLeft, Send, Loader2 } from 'lucide-react';
import SurveyQuestion from './components/SurveyQuestion';
import * as API from '../../../app/api';
import BtnClose from '../../components/BtnClose';

interface NPSSurveyProps {
	id_survey?: string;
	survey?: string;
	onClose: Function;
	onSubmit?: (responses: Record<string, any>) => void;
}

interface Question {
    id_survey_questions: string;
    title: string;
    type: string;
    value: any;
    options?: string[];
}

export default function NPSSurvey({ onSubmit, id_survey, survey, onClose }: NPSSurveyProps) {
	const [responses, setResponses] = useState<Record<string, any>>({});
	const [currentStep, setCurrentStep] = useState(0);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	

	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	//const [survey, setSurvey] = useState<any>(null);
	const [questions, setQuestions] = useState<Question[]>([]);

	useEffect(() => {
		if (!id_survey && !survey) return;
		getSurvey(survey);
	}, []);

	const getSurvey = async(survey:any = null) => {
		let response = null;
		if (id_survey) {
			response = await API.getSurvey(id_survey);
		} else if (survey) {
			response = {data:survey};
		}
		const questions = JSON.parse(response.data.questions);
		
		//setSurvey(response.data);
		setTitle(response.data.title);
		setDescription(response.data.description);
		setQuestions(questions.sort((a:any, b:any) => a.order_position - b.order_position));
	}

	const getQuestionType = (id_survey_questions:string):any => {
		return questions.find((i:any) => i.id_survey_questions == id_survey_questions);
	}

	const handleResponse = (questionId: string, value: any) => {
		setResponses(prev => ({
		...prev,
		[questionId]: value
		}));

		const question:Question = getQuestionType(questionId);
		if (question.type == 'text' || question.type == 'slider') return;

		if (currentStep < questions.length - 1) {
		setTimeout(() => setCurrentStep(prev => prev + 1), 400);
		}
	};

	const getData = () => {
		const data:any = [];
		Object.entries(responses).forEach(([id, value]) => {
			data.push({id, value});
		});
		return {id_survey, data};
	}

	const handleSubmit = async () => {
		setIsSubmitting(true);
		if (id_survey) {
			await new Promise(resolve => setTimeout(resolve, 1000));
		} else {
			await API.submitSurvey(getData());
			await new Promise(resolve => setTimeout(resolve, 300));
		}
		
		onSubmit?.(responses);
		setSubmitted(true);
		setIsSubmitting(false);
	};

	const onCloseClick = () => {
		onClose();
	}

	const currentQuestion = questions[currentStep];
	const progress = ((currentStep + 1) / questions.length) * 100;

	if (submitted) {
		return (
			<div className='bg-gradient-to-b from-blue-50 ' style={{ borderRadius: '15px' }}>
				<div className='d-flex justify-content-end'>
			<BtnClose onClick={onCloseClick} />    
		</div>
		<div className="h-500px  to-white flex items-center justify-center p-4" style={{ borderRadius: '15px' }}>
			
			<div className="max-w-lg w-full text-center">
				<div className="w-16 h-16 bg-green-100  flex items-center justify-center mx-auto mb-6">
					<Send className="w-8 h-8 text-green-600" />
				</div>
				<h2 className="text-2xl font-bold text-gray-900 mb-3">Obrigado pelo seu feedback!</h2>
				<p className="text-gray-600">
					Suas respostas são muito importantes para continuarmos melhorando nossos serviços.
				</p>
			</div>
		</div>
		<div className='text-center'>
                        <button type='submit' id='kt_sign_in_submit' className='btn btn-sm btn-primary w-25 mb-10' onClick={onCloseClick}>
                        Fechar
                        </button>
                        
                    </div>
			</div>
		
		);
	}

	return (
		<div className="mb-10">
		{/* Progress bar */}
		<div className='d-flex justify-content-end'>
			<BtnClose onClick={onCloseClick} />    
		</div>
		<div className="fixed top-0 left-0 w-full h-1 bg-gray-100">
			<div 
			className="h-full bg-blue-600 transition-all duration-300"
			style={{ width: `${progress}%` }}
			/>
		</div>

		<div className="max-w-4xl mx-auto p-4">
			{/* Header */}
			<div className="py-8 text-center">
			<button 
				onClick={() => currentStep > 0 && setCurrentStep(prev => prev - 1)}
				className={`absolute left-4 top-4 p-2 rounded-lg hover:bg-white/50 transition-colors
				${currentStep === 0 ? 'invisible' : ''}`}
			>
				<ChevronLeft className="w-6 h-6 text-gray-600" />
			</button>
			
			<h1 className="text-2xl font-bold text-gray-900 mb-2">{title}</h1>
			<p className="text-gray-600">{description}</p>
			</div>

			{/* Current question */}
			<div className="space-y-6">
			{ currentQuestion ? 
			<SurveyQuestion
				key={currentQuestion.id_survey_questions}
				title={currentQuestion.title}
				type={currentQuestion.type as any}
				value={responses[currentQuestion.id_survey_questions]}
				onChange={(value) => handleResponse(currentQuestion.id_survey_questions, value)}
				options={currentQuestion.options}
			/> : null }

			{/* Navigation/Submit */}
			<div className="flex justify-between items-center pt-6">
				<button
				onClick={() => currentStep > 0 && setCurrentStep(prev => prev - 1)}
				className={`px-6 py-2 text-gray-600 hover:text-gray-900 transition-colors
					${currentStep === 0 ? 'invisible' : ''}`}
				>
				Anterior
				</button>

				{currentStep === questions.length - 1 ? (
				<button
					onClick={handleSubmit}
					disabled={isSubmitting || !responses[currentQuestion?.id_survey_questions]}
					className="flex items-center gap-2 px-8 py-3 bg-blue-600 text-white rounded-lg
							hover:bg-blue-700 transition-colors disabled:opacity-50
							disabled:cursor-not-allowed"
				>
					{isSubmitting ? (
					<>
						<Loader2 className="w-5 h-5 animate-spin" />
						Enviando...
					</>
					) : (
					<>
						<Send className="w-5 h-5" />
						Enviar
					</>
					)}
				</button>
				) : (
				<button
					onClick={() => currentStep < questions.length - 1 && setCurrentStep(prev => prev + 1)}
					disabled={!responses[currentQuestion?.id_survey_questions]}
					className="px-6 py-2 text-blue-600 hover:text-blue-800 transition-colors
							disabled:opacity-50 disabled:cursor-not-allowed"
				>
					Próxima
				</button>
				)}
			</div>
			</div>

			{/* Steps indicator */}
			<div className="flex justify-center gap-1.5 mt-12">
			{questions.map((_, index) => (
				<div
				key={index}
				className={`w-2 h-2 rounded-full transition-all duration-300 
					${index === currentStep 
					? 'bg-blue-600 scale-125'
					: index < currentStep
						? 'bg-blue-200'
						: 'bg-gray-200'
					}`}
				/>
			))}
			</div>
		</div>
		</div>
	);
}