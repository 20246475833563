import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import * as API from '../../../api';
import { DomainInput } from './DomainInput';
import { UpdateButton } from './UpdateButton';

const CompanyConfig = () => {
    const [loading, setLoading] = useState(false);
    const [currentDomains, setCurrentDomains] = useState<string[]>([]);
    const [domains, setDomains] = useState<string[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const validateDomains = (domains: string[]): boolean => {
        const domainPattern = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
        return domains.every(domain => 
            domain.length === 0 || domainPattern.test(domain)
        );
    };

  const getData = async () => {
    try {
      const response = await API.getCompanyDomain();
      const initialDomains = response.data.domain
        ? response.data.domain.split(',').map((d: string) => d.trim())
        : [];
      setCurrentDomains(initialDomains);
      setDomains(initialDomains);
    } catch (error) {
      console.error('Error fetching domains:', error);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const domainsString = domains
        .filter(d => d.trim().length > 0)
        .join(',');
      await API.updateDomain(domainsString);
      //window.location.reload();
      setLoading(false);
    } catch (error) {
      console.error('Error updating domains:', error);
      setLoading(false);
    }
  };

  const handleAddDomain = () => {
    setDomains([...domains, '']);
  };

  const handleRemoveDomain = (index: number) => {
    setDomains(domains.filter((_, i) => i !== index));
  };

  const handleDomainChange = (value: string, index: number) => {
    const newDomains = [...domains];
    newDomains[index] = value;
    setDomains(newDomains);
  };

  const isValid = validateDomains(domains);
  const hasChanges = JSON.stringify(domains) !== JSON.stringify(currentDomains);
  const isDisabled = !isValid || !hasChanges;

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <PageTitle>Configurações de Domínio</PageTitle>
        
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6 mt-6">
          <div className="prose prose-sm mb-6">
            <p className="text-gray-600">
              Configure um ou mais domínios para sua empresa. Deixe vazio se não desejar 
              configurar nenhum domínio.
            </p>
          </div>

          <DomainInput 
            domains={domains}
            onAdd={handleAddDomain}
            onRemove={handleRemoveDomain}
            onChange={handleDomainChange}
          />

          {!isValid && (
            <p className="mt-2 text-sm text-red-600">
              Um ou mais domínios contêm formato inválido
            </p>
          )}

          <UpdateButton
            loading={loading}
            disabled={isDisabled}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyConfig;