import React from 'react';
import { Upload } from 'lucide-react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

type ImageUploadProps = {
  croppedImage: string;
  uploadResponse: any;
  userData?: any;
  onImageSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef: React.RefObject<HTMLInputElement>;
};

export const ImageUpload = ({ croppedImage, uploadResponse, userData, onImageSelect, inputRef }: ImageUploadProps) => (
  <div className="flex flex-col items-center gap-4">
    <div className="relative w-40 h-40 rounded-full overflow-hidden border-2 border-gray-200">
      <img
        src={
          croppedImage || 
          (uploadResponse?.path || userData?.src) || 
          toAbsoluteUrl('/media/icons/duotune/general/gen049.svg')
        }
        alt="Profile"
        className="w-full h-full object-cover"
      />
    </div>
    
    <button
      type="button"
      onClick={() => inputRef.current?.click()}
      className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
    >
      <Upload className="w-5 h-5 mr-2" />
      Alterar imagem
    </button>
    
    <input
      ref={inputRef}
      type="file"
      accept="image/*"
      onChange={onImageSelect}
      className="hidden"
    />
  </div>
);