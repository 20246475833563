export const messages:any = {
    USER_ALREADY_EXIST:'Usuário já cadastrado',
    USERS_LIMIT_REACHED:'Limite máximo de usuários já cadastrados para essa empresa',

    COMPANY_NOT_FOUND:'Empresa não encontrada',
    PERMISSION_DENIED:"Você não tem permissão para executar esta ação",
    INVALID_TOKEN:"Chave de acesso inválida, acesse novamente o sistema",
    AUTHENTICATION_FAIL:"Falha de autenticação, cheque se os dados estão corretos e tente novamente",
    EMAIL_ALREADY_REGISTERED:'Esse e-mail já está sendo usado'
}

export function message(key:string) {
    return messages[key];
}