/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import GridLayout from "react-grid-layout";
import styled from '@emotion/styled';
//import HomePage from './HomePage';
import Dashboard from './../dashboard/Dashboard';

const StyledGridLayout = styled(GridLayout)`
    background-color: white;

    .closebutton {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

interface Props {
  edit:boolean,
  width:number,
  data:any[],
  onLayoutChange?: (layour:GridLayout.Layout[]) => void,
  onDeleteChart?: (guid:string) => void,
  onDoubleClickChart?: (guid:string) => void
}

const HomeWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.HOME'})}</PageTitle>
      <Dashboard home={true}/>
    </>
  )
}

export {HomeWrapper}
