import React, { FC, useEffect, useRef, useState } from 'react';
import { X, Loader2, Save } from 'lucide-react';
import { sha256 } from 'crypto-hash';
import { messages } from '../../utils/ErrorsText';
import { isSuperAdmin, getUserData } from '../../../modules/auth/core/Auth';
import CropperComponent from '../../../pages/utils/ImageCropper';
import * as API from '../../../api';
import { ImageUpload } from './ImageUpload';
import { UserForm } from './UserForm';

interface Props {
  userData?: any;
  onUserCreated: Function;
  onUserUpdated: Function;
  onClose: Function;
}

const UserModal: FC<Props> = ({ userData, onUserCreated, onUserUpdated, onClose }) => {
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  
  // Form state
  const [name, setName] = useState(userData?.name || '');
  const [email, setEmail] = useState(userData?.email || '');
  const [isAdmin, setIsAdmin] = useState(userData ? userData.is_admin : 0);
  const [isActived, setIsActived] = useState(userData ? userData.is_actived : 1);
  const [isMailling, setIsMailling] = useState(userData ? userData.is_mailling : 1);
  const [isViewer, setIsViewer] = useState(userData ? userData.is_viewer : 0);
  const [status, setStatus] = useState('');
  const [domain, setDomain] = useState('');

  // Image state
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [croppedImage, setCroppedImage] = useState('');
  const [uploadResponse, setUploadResponse] = useState<any>();
  const [showCropper, setShowCropper] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await API.getCompanyDomain();
      setDomain(response.data.domain);
    } catch (error) {
      console.error('Error fetching domain:', error);
    }
  };

  const validateEmail = () => {
    if (userData && userData.email === email) return 'is-valid';
    
    const emailRegex = /^[\w-\.+\-]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!emailRegex.test(email)) return 'is-invalid';
    
    if (!domain) return 'is-valid';
    
    const domains = domain.split(',').map(d => d.trim());
    return domains.some(d => email.endsWith(`@${d}`)) ? 'is-valid' : 'is-invalid';
  };

  const validateName = () => {
    if (name.length < 5) return 'is-invalid';
    return '';
  };

  const validationStatus = () => {
    if (validateEmail() !== 'is-valid') return 'E-mail inválido';
    if (name.length < 5) return 'Nome de usuário deve conter no mínimo 5 caracteres';
    return '';
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFile(e.target.files[0]);
      setShowCropper(true);
    }
  };

  const handleSubmit = async () => {
    const validationResult = validationStatus();
    setStatus(validationResult);
    if (validationResult) return;

    setLoading(true);
    try {
      if (userData) {
        const response = await API.updateUser({
          user_guid: userData.guid,
          name,
          email,
          id_upload: uploadResponse?.id_upload,
          is_actived: isActived,
          is_mailling: isMailling,
          is_viewer: isViewer,
          is_admin: isAdmin,
          company_guid: userData.company_guid
        });

        if (!response.success) {
          setStatus(messages[response.error]);
          return;
        }
        onUserUpdated();
      } else {
        const companyGuid = isSuperAdmin() 
          ? localStorage.getItem("current_company") 
          : getUserData().company_guid;

        const response = await API.createUser({
          name,
          email,
          password: await sha256(new Date().getTime().toString()),
          id_upload: uploadResponse?.id_upload,
          is_actived: isActived,
          is_mailling: isMailling,
          is_viewer: isViewer,
          is_admin: isAdmin,
          company_guid: companyGuid
        });

        if (!response.success) {
          setStatus(messages[response.error]);
          return;
        }
        onUserCreated();
      }
    } catch (error) {
      console.error('Error submitting user:', error);
      setStatus('Erro ao processar requisição');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        {/* Backdrop */}
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" onClick={() => onClose()} />

        {/* Modal Content */}
        <div className="relative bg-white rounded-2xl shadow-xl w-[95vw] max-w-5xl overflow-hidden">
          {/* Header */}
          <div className="px-8 py-6 bg-gradient-to-r from-blue-600 to-blue-700 flex items-center justify-between">
            <h3 className="text-xl font-semibold text-white">
              {userData ? 'Editar Usuário' : 'Adicionar Usuário'}
            </h3>
            <button
              onClick={() => onClose()}
              className="p-2 text-white/80 hover:text-white hover:bg-white/10 rounded-lg transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Content */}
          <div className="p-8">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
              {/* Left Column - Image */}
              <div>
                <ImageUpload
                  croppedImage={croppedImage}
                  uploadResponse={uploadResponse}
                  userData={userData}
                  onImageSelect={handleImageSelect}
                  inputRef={inputRef}
                />

                {showCropper && imageFile && (
                  <div className="mt-6">
                    <CropperComponent
                      imageFile={imageFile}
                      setCroppedImage={setCroppedImage}
                      setShowCropper={setShowCropper}
                      setUploadResponse={setUploadResponse}
                    />
                  </div>
                )}
              </div>

              {/* Right Column - Form */}
              <div className="lg:col-span-2">
                <UserForm
                  name={name}
                  email={email}
                  isAdmin={isAdmin}
                  isActived={isActived}
                  isMailling={isMailling}
                  isViewer={isViewer}
                  domain={domain}
                  validateEmail={validateEmail}
                  validateName={validateName}
                  onNameChange={setName}
                  onEmailChange={setEmail}
                  onAdminChange={setIsAdmin}
                  onActivedChange={setIsActived}
                  onMaillingChange={setIsMailling}
                  onViewerChange={setIsViewer}
                />

                {status && (
                  <div className="mt-6 p-4 bg-red-50 border border-red-200 rounded-xl">
                    <p className="text-sm text-red-600">{status}</p>
                  </div>
                )}
              </div>
            </div>

            {/* Footer */}
            <div className="mt-8 pt-6 border-t border-gray-200 flex justify-end">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={!!validationStatus() || loading}
                className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed shadow-sm hover:shadow"
              >
                {loading ? (
                  <>
                    <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                    <span>Processando...</span>
                  </>
                ) : (
                  <>
                    <Save className="w-5 h-5 mr-2" />
                    <span>{userData ? 'Atualizar dados' : 'Criar usuário'}</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserModal;