import { FC, useState, useEffect } from 'react';
import BtnClose from '../../components/BtnClose';
import styled from '@emotion/styled';
import Select from 'react-select';
import ReportFilter from './ReportFilter';
import { useRegions } from '../../../app/modules/auth';

const Style = styled.div`

  .modal-container {
    width: 100%;
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select{
    width: 100%;
    margin-top: 1rem;
  }

  .mailling-item {
    margin-right: 30px;
    
    label {
        margin-right: 10px;
    }
}
`;

type Props = {
    onChange: Function,
    onClose: Function,
    data: any,
    type: string
}

const ApuracaoFilterModal: FC<Props> = (props) => {
    const regions = useRegions();

    const [loading, setLoading] = useState(false);

    const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
    const [status, setStatus] = useState<string>('apuracao');
    const [year, setYear] = useState<string>('2024');
    const [month, setMonth] = useState<string>('Janeiro');

    const [filter, setFilter] = useState<any>(null);

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (filter || props.data) {
            const data = filter?.data || props.data;
            if (data.status) setStatus(data.status);
            if (data.month) setMonth(data.month);
            if (data.year) setYear(data.year);
            if (data.regions) setSelectedRegions(data.regions);
        }
    }, [props.data, filter]);

    function getData() {
        return {
            status,
            regions:selectedRegions,
            year,
            month
        }
    }

    return (
        <Style>
            <div className='modal-container'>
                
                <div className='px-7 py-5 d-flex justify-content-between'>
                    <div className='pt-2 fs-3 text-dark fw-bolder'>Filtros para o relatório</div>
                    <BtnClose onClick={() => props.onClose()} />    
                </div>
                <ReportFilter name={props.type} filter={getData()} setFilter={setFilter}></ReportFilter>
                <div className='separator border-gray-200'></div>
                <div className='px-7 py-5'>
                    <div className='mb-4 d-flex flex-wrap'>
                        <div className=' justify-content-between modal-container'>
                            
                            { props.type != 'sellout' ? 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Status:</label>
                                <select  disabled={loading} className='form-select form-select-solid' data-kt-select2='true' data-placeholder='' data-allow-clear='true' value={status} onChange={(evt:any) => setStatus(evt.target.value)}>
                                    <option value='apuracao'>Apuração</option>
                                    <option value='cancelados'>Cancelados</option>
                                </select>
                            </div> : null }

                            { props.type != 'sellout' ? 
                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px'>Regional:</label>
                                <Select
                                    isMulti
                                    isDisabled={loading}
                                    options={regions}
                                    value={selectedRegions}
                                    closeMenuOnSelect={false}
                                    onChange={(selected: any) => setSelectedRegions(selected)}
                                    placeholder={'Filtrar por Regional'}
                                    className="multi-select"
                                />
                            </div> : null }

                            <div className='form-group'>
                                <label className='form-label fw-bold me-2 w-200px me-20'>Período:</label>
                                
                                <select  disabled={loading} className='form-select form-select-solid ms-5' data-kt-select2='true' data-placeholder='' data-allow-clear='true' value={month} onChange={(evt:any) => setMonth(evt.target.value)}>
                                    <option value='01'>Janeiro</option>
                                    <option value='02'>Fevereiro</option>
                                    <option value='03'>Março</option>
                                    <option value='04'>Abril</option>
                                    <option value='05'>Maio</option>
                                    <option value='06'>Junho</option>
                                    <option value='07'>Julho</option>
                                    <option value='08'>Agosto</option>
                                    <option value='09'>Setembro</option>
                                    <option value='10'>Outubro</option>
                                    <option value='11'>Novembro</option>
                                    <option value='12'>Dezembro</option>
                                </select>
                                <select  disabled={loading} className='form-select form-select-solid' data-kt-select2='true' data-placeholder='' data-allow-clear='true' value={year} onChange={(evt:any) => setYear(evt.target.value)}>
                                    <option value='2024'>2024</option>
                                    <option value='2023'>2023</option>
                                </select>
                            </div>

                            <button onClick={() => {
                                setLoading(true);
                                props.onChange(getData());
                            }} className='btn btn-primary filter-btn'>{!loading && 'Filtrar'} {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                    Aguarde...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Style>
    )
}

export default ApuracaoFilterModal;