import React from 'react';
import Select from 'react-select';

type CompanySelectProps = {
  companies: any[];
  selectedCompanies: any[];
  onChange: (selected: any) => void;
};

export const CompanySelect = ({ companies, selectedCompanies, onChange }: CompanySelectProps) => (
  <div className="space-y-1">
    <label className="block text-sm font-medium text-gray-700">
      Selecionar Empresas:
    </label>
    <Select
      isMulti
      options={companies}
      value={selectedCompanies}
      onChange={onChange}
      closeMenuOnSelect={false}
      placeholder="Selecionar empresas..."
      className="w-full"
      classNamePrefix="react-select"
    />
  </div>
);