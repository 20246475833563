/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import { AddMenuItemData, AddMenuItemType, AddMenuItemTypeData, defaultAddMenuItemData, InputAddMenuItemTypeData} from './AddMenuItemModels'
import { AddMenuItem } from './AddMenuItem'
import { createCategory, createProduct, createSubProduct, deleteCategory, deleteProduct, deleteSubProduct, loadGeneralMenu, getMenu } from '../../../../app/api'
import { getUserData } from '../../../../app/modules/auth'
import { InputAddMenuItem } from './InputAddMenuItem'

type Props = {
  show: boolean,
  menu?: any[],
  handleClose: () => void,
  onMenuChanged: () => void
}

const AddMenuItemModal: React.FC<Props> = ({show, menu, handleClose, onMenuChanged}: Props) => {
  const companyGuid = getUserData()?.company_guid;
  const [categories, setCategories] = useState<Array<any>>([]);
  const [data, setData] = useState<AddMenuItemData | undefined>(undefined);

  const getDefaultData = (categories: Array<any>): AddMenuItemData => {
    return {
      ...defaultAddMenuItemData,
      categories: categories,
    };
  }

  useEffect(() => {
    loadGeneralMenu().then(response => {
      const data = mapData(response.data);
      setCategories(data);
      setData(getDefaultData(data));
    })
		
	}, [show])

  function mapData(categories:any) {
    categories.map((category:any) => {
      category.children = category.products;
      category.section = category.name;
      category.id = category.guid;
      delete category.products;
      category.children.map((product:any) => {
        product.id = product.guid;
        product.label = product.name;
        product.hasLabel = 1;
        product.to = '';
        product.children = product.subproducts;
        delete product.subproducts;
        product.children.map((subproduct:any) => {
          subproduct.id = subproduct.guid;
          subproduct.label = subproduct.name;
          subproduct.hasBullet = 1;
          subproduct.to = '';
        })
      });
    });
    return categories;
  }


  const updateData = useCallback((fieldsToUpdate: Partial<AddMenuItemData | undefined>) => {
    const updatedData = {
      ...data,
      ...fieldsToUpdate
    };

    setData(updatedData as AddMenuItemData)
  }, [data]);

  const onHideModal = () => {
    setTimeout(() => setData(getDefaultData(categories)), 500);
    handleClose();
  }

  function submit() {
    onMenuChanged();
    onHideModal();
  }

  const getInputTypeData = (type: AddMenuItemType): InputAddMenuItemTypeData | undefined => {
    switch(type) {
      case AddMenuItemType.CATEGORY: {
        return {
          inputName: 'category',
          inputPlaceholder: 'Digite a categoria...',
          label: 'Categoria',
        };
      }

      case AddMenuItemType.PRODUCT: {
        return {
          inputName: 'product',
          inputPlaceholder: 'Digite o produto...',
          label: 'Produto',
        };
      }

      case AddMenuItemType.SUBPRODUCT: {
        return {
          inputName: 'subProduct',
          inputPlaceholder: 'Digite o sub produto...',
          label: 'Sub Produto',
        };
      }

      default: return undefined
    }
  }

  const getTypeData = (type: AddMenuItemType): AddMenuItemTypeData | undefined => {
    switch(type) {
      case AddMenuItemType.CATEGORY: {
        return {
          inputName: 'category',
          inputPlaceholder: 'Digite a categoria...',
          label: 'Categoria',
          option: 'Selecione uma categoria...',
        };
      }

      case AddMenuItemType.PRODUCT: {
        return {
          inputName: 'product',
          inputPlaceholder: 'Digite o produto...',
          label: 'Produto',
          option: 'Selecione um produto...',
        };
      }

      case AddMenuItemType.SUBPRODUCT: {
        return {
          inputName: 'subProduct',
          inputPlaceholder: 'Digite o sub produto...',
          label: 'Sub Produto',
          option: 'Selecione um sub produto...',
        };
      }

      default: return undefined
    }
  }

  const onSelectedItem = (type: AddMenuItemType, item: any) => {
    switch (type) {
      case AddMenuItemType.CATEGORY: {
        let products: Array<any> | undefined = undefined;
        if (item !== undefined) products = item.children;

        setTimeout(() => {
          updateData({
            ...data,
            category: item,
            products: products,
            subProducts: undefined,
          });
        }, 200)
        break;
      }

      case AddMenuItemType.PRODUCT: {
        let subProducts: Array<any> | undefined = undefined;
        if (item !== undefined) subProducts = item.children;

        setTimeout(() => {
          updateData({
            ...data,
            product: item,
            subProducts: subProducts,
          });
        }, 200)
        break;
      }

      case AddMenuItemType.SUBPRODUCT: {
        updateData({
          ...data,
          subProduct: item,
        });
        break;
      }

      default: {}
    }
  }

  const onAddingMode = (type: AddMenuItemType, adding: boolean) => {
    if (!adding) return;

    switch(type) {
      case AddMenuItemType.CATEGORY: {
        updateData({
          ...data,
          products: undefined,
          subProducts: undefined,
        });
        break;
      }

      case AddMenuItemType.PRODUCT: {
        updateData({
          ...data,
          subProducts: undefined,
        });
        break;
      }
    }
  }

  const onAddingItem = async (type: AddMenuItemType, item: string) => {
    switch(type) {
      case AddMenuItemType.CATEGORY: {
        const result = await createCategory(item);
        if (result) {
          submit()
          return
        }

        //TODO implementar a exibição de erro
        console.log(`Não foi possível criar a categoria: ${item}`);
        break;
      }

      case AddMenuItemType.PRODUCT: {
        const result = await createProduct(item, data?.category.id);
        if (result) {
          submit()
          return
        }

        //TODO implementar a exibição de erro
        console.log(`Não foi possível criar o produto: ${item}`);
        break;
      }

      case AddMenuItemType.SUBPRODUCT: {
        const result = await createSubProduct(item, data?.product.id);
        if (result) {
          submit()
          return
        }

        //TODO implementar a exibição de erro
        console.log(`Não foi possível criar o sub produto: ${item}`);
        break;
      }

      default: {}
    }
  }

  const onDeletingItem = async (type: AddMenuItemType, item: any | undefined) => {
    if (item === undefined) return;

    switch (type) {
      case AddMenuItemType.CATEGORY: {
        const result = await deleteCategory(item.id);
        if (result) {
          submit()
          return;
        }

        //TODO implementar a exibição de erro
        console.log(`Não foi possível deletar a categoria: ${item}`);
        break;
      }

      case AddMenuItemType.PRODUCT: {
        const result = await deleteProduct(item.id);
        if (result) {
          submit()
          return;
        }

        //TODO implementar a exibição de erro
        console.log(`Não foi possível deletar o produto: ${item}`);
        break;
      }

      case AddMenuItemType.SUBPRODUCT: {
        const result = await deleteSubProduct(item.id);
        if (result) {
          submit()
          return;
        }

        //TODO implementar a exibição de erro
        console.log(`Não foi possível deletar o sub produto: ${item}`);
        break;
      }

      default: {}
    }
  }

  const shouldShowInputAddItem = (type: AddMenuItemType): boolean => {
    let value: boolean = false
    switch (type) {
      case AddMenuItemType.CATEGORY: {
        value = data?.category !== undefined && data?.products === null;
        break;
      }

      case AddMenuItemType.PRODUCT: {
        value = data?.product !== undefined && data?.subProducts === null;
        break;
      }

      default: {}
    }
    return value
  }

  const showCategory = () => {
    if (!categories) return <></>;
    return (
      <>
        <AddMenuItem
          type={AddMenuItemType.CATEGORY}
          typeData={getTypeData(AddMenuItemType.CATEGORY)}
          data={categories}
          onSelectedItem={onSelectedItem}
          onAddingItem={onAddingItem}
          onDeletingItem={onDeletingItem}
          onAddingMode={onAddingMode}/>
          
        {
          shouldShowInputAddItem(AddMenuItemType.CATEGORY) ?
          <InputAddMenuItem
            typeData={getInputTypeData(AddMenuItemType.PRODUCT)}
            onSavingItem={(newItem: string) => {
              onAddingItem(AddMenuItemType.PRODUCT, newItem);
            }}/> : <></>
        }
      </>
    );
  }

  const showProduct = () => {
    if (data?.products === undefined || data.products === null) return <></>;
    return (
      <>
        <AddMenuItem
          type={AddMenuItemType.PRODUCT}
          typeData={getTypeData(AddMenuItemType.PRODUCT)}
          data={data.products}
          onSelectedItem={onSelectedItem}
          onAddingItem={onAddingItem}
          onDeletingItem={onDeletingItem}
          onAddingMode={onAddingMode}/>
          {
          shouldShowInputAddItem(AddMenuItemType.PRODUCT) ?
          <InputAddMenuItem
            typeData={getInputTypeData(AddMenuItemType.SUBPRODUCT)}
            onSavingItem={(newItem: string) => {
              onAddingItem(AddMenuItemType.SUBPRODUCT, newItem);
            }}/> : <></>
        }
      </>
    );
  }

  const showSubProduct = () => {
    if (data?.subProducts === undefined || data.subProducts === null) return <></>;
    return (
      <>
        <AddMenuItem
          type={AddMenuItemType.SUBPRODUCT}
          typeData={getTypeData(AddMenuItemType.SUBPRODUCT)}
          data={data.subProducts}
          onSelectedItem={onSelectedItem}
          onAddingItem={onAddingItem}
          onDeletingItem={onDeletingItem}
          onAddingMode={onAddingMode}/>
      </>
    );
  }

  const showHeader = () => {
    return (
      <>
        <div className='modal-header'>
          <h2>Editar Menu</h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
          {/* end::Close */}
        </div>
      </>
    );
  }

  return <>
    <Modal
      id='kt_modal_add_menu_item'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={onHideModal}>

      {showHeader()}
      <div className='modal-body py-lg-10 px-lg-10' style={{height:'340px'}}>
        {/*begin::Stepper */}
        <div
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'>
          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_add_item_form'>
              {showCategory()}
              {showProduct()}
              {showSubProduct()}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>
  </>
}

export {AddMenuItemModal}
