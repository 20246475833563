import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {KTSVG, toAbsoluteUrl} from './../../../_metronic/helpers'
import { useParams } from 'react-router';
import BtnClose from './../../components/BtnClose';

import * as API from './../../api';
import IChart from './../dashboard/IChart';
import IPowerBI from './../dashboard/IPowerBI';

interface Props {
    userData:any,
    onUserDeleted: Function,
    onClose: Function
}

const StyledModal = styled.div`
    background-color: white;

    .card-header {
        align-items: center;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .remove-user-title {
        margin:20px !important;
    }
`;

const DeleteUserModal:FC<Props> = (props:Props) => {
    const [loading, setLoading] = useState<boolean>(false);

    async function onDeleteClick() {
        const response = await API.deleteUser(props.userData.guid);
        props.onUserDeleted();
    }

    return <StyledModal className="card mb-5 mb-xl-10">
        <div className='card-header border-0'>
            <div className='card-title m-0'>
                <h3>Remover Usuário</h3>
            </div>

            <BtnClose onClick={() => props.onClose()} />
        </div>
        <div className='form w-100'>
            <div className='card mb-xl-10'>
                <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='me-7'>
                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                        <img src={toAbsoluteUrl('/media/icons/duotune/general/gen049.svg')} alt='Metornic' />
                    </div>
                    </div>

                    <div className='flex-grow-1'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                        <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center mb-2'>
                            <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>{props.userData?.name}</a>
                            {props.userData?.is_admin ? 
                            <KTSVG
                                path='/media/icons/duotune/general/gen026.svg'
                                className='svg-icon-1 svg-icon-primary'
                            />
                            
                            :<></>}
                        </div>

                        <div className='d-flex flex-wrap fw-bold fs-6 mb-4'>
                            
                            <p className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
                            <KTSVG path='/media/icons/duotune/communication/com011.svg' className='svg-icon-4 me-1'/>
                                { props.userData?.email }
                            </p>
                        </div>
                        </div>

                        
                    </div>
                    
                    <p>Tem certeza que deseja remover este usuário?</p>
                    <div className=''>
                        <button type='submit' onClick={() => { onDeleteClick() }} id='kt_sign_in_submit' className='btn btn-sm btn-danger'>
                        {!loading ? <span className='indicator-label'>Remover usuário</span> : <></>}
                        {loading ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                Removendo usuário, aguarde por favor...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        ) : <></>}
                        </button>
                        <button onClick={() => { props.onClose()}} className='btn btn-sm btn-primary m-1'>Cancelar</button>
                        </div>
                    </div>
                        
                    </div>
                    
                    
                </div>
                
            </div>
        </div>
    </StyledModal>
};

export default DeleteUserModal;