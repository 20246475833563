import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import * as API from '../../../api';
import { ConfirmModal } from './ConfirmModal';
import { FeatureHeader } from './FeatureHeader';
import { UserRow } from './UserRow';

export const FeatureMatrix = () => {
  const [menu, setMenu] = useState<any>();
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [usersData, setUsersData] = useState<any>([]);
  const [confirmModal, setConfirmModal] = useState<any>({
    isOpen: false,
    feature: null,
    action: 'activate' as 'activate' | 'deactivate'
  });
  const [updateIndex, setUpdateIndex] = useState<number>(0);

  useEffect(() => {
    getFeatures();
  }, []);

  useEffect(() => {
    if (selectedUsers.length) {
      setUsersData(selectedUsers);
    } else {
      if (menu) setUsersData(menu.users);
    }
  }, [selectedUsers, menu?.users]);

  const getFeatures = async () => {
    const result = await API.getUserFeatures();
    setMenu(result.data);
    if (result.success) {
      const formattedUsers = result.data.users.map((item: any) => ({
        value: item.id_user,
        label: item.name,
        ...item
      }));
      setUsers(formattedUsers);
    }
  };

  const handleFeatureClick = (feature: any) => {
    // Check if all users have this permission
    const allChecked = usersData.every((user:any) => 
      menu.permissions.some((p: any) => 
        p.id_feature === feature.id_feature && p.id_user === user.id_user
      )
    );

    setConfirmModal({
      isOpen: true,
      feature,
      action: allChecked ? 'deactivate' : 'activate'
    });
  };

  const handleConfirmFeature = async () => {
    const { feature, action } = confirmModal;
    
    // Update permissions for all users
    for (const user of usersData) {
      if (action === 'activate') {
        await API.addFeatureUserPermission({
          id_feature: feature.id_feature,
          guid: user.guid
        });
      } else {
        await API.removeFeatureUserPermission({
          id_feature: feature.id_feature,
          guid: user.guid
        });
      }
    }
    // Refresh data
    await getFeatures();
    setUpdateIndex(updateIndex + 1)
    setConfirmModal({ isOpen: false, feature: null, action: 'activate' });
  };

  const handlePermissionChange = async (featureId: number, userId: number, checked: boolean) => {
    if (checked) {
      await API.addFeatureUserPermission({ id_feature: featureId, guid: userId });
    } else {
      await API.removeFeatureUserPermission({ id_feature: featureId, guid: userId });
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Buscar por Usuários:
        </label>
        <Select
          isMulti
          options={users}
          value={selectedUsers}
          closeMenuOnSelect={false}
          placeholder="Selecionar usuários"
          onChange={setSelectedUsers}
          className="w-full"
        />
      </div>

      {menu?.features?.length ? (
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky left-0 bg-gray-50">
                    Usuário
                  </th>
                  {menu.features.map((feature: any) => (
                    <FeatureHeader
                      key={feature.id_feature}
                      feature={feature}
                      onFeatureClick={handleFeatureClick}
                    />
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {usersData.map((user: any) => (
                  <UserRow
                    key={user.guid + "_" + updateIndex}
                    user={user}
                    features={menu.features}
                    permissions={menu.permissions}
                    onPermissionChange={handlePermissionChange}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-center py-12 text-gray-500">
          Nenhuma funcionalidade encontrada para o seu plano atual
        </div>
      )}

      <ConfirmModal
        isOpen={confirmModal.isOpen}
        onClose={() => setConfirmModal({ ...confirmModal, isOpen: false })}
        onConfirm={handleConfirmFeature}
        title={confirmModal.feature?.title || ''}
        action={confirmModal.action}
      />
    </div>
  );
};