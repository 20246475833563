import React from 'react';
import { AlertTriangle, X, Check, Ban } from 'lucide-react';
import clsx from 'clsx';

type ConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  action: 'activate' | 'deactivate';
};

export const ConfirmModal = ({ isOpen, onClose, onConfirm, title, action }: ConfirmModalProps) => {
  if (!isOpen) return null;

  const isActivate = action === 'activate';

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose} />
        
        <div className="relative bg-white rounded-2xl shadow-xl max-w-md w-full overflow-hidden">
          {/* Header */}
          <div className={clsx(
            "px-6 py-4 flex items-center justify-between",
            isActivate 
              ? "bg-gradient-to-r from-green-600 to-green-700"
              : "bg-gradient-to-r from-red-600 to-red-700"
          )}>
            <div className="flex items-center gap-3">
              {isActivate ? (
                <Check className="w-6 h-6 text-white" />
              ) : (
                <Ban className="w-6 h-6 text-white" />
              )}
              <h3 className="text-lg font-semibold text-white">
                {isActivate ? 'Ativar Permissão' : 'Desativar Permissão'}
              </h3>
            </div>
            <button
              onClick={onClose}
              className="text-white/80 hover:text-white hover:bg-white/10 rounded-lg p-2 transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Content */}
          <div className="p-6">
            <div className="flex items-start gap-4">
              <div className={clsx(
                "p-3 rounded-full",
                isActivate ? "bg-green-50" : "bg-red-50"
              )}>
                {isActivate ? (
                  <Check className={clsx(
                    "w-6 h-6",
                    isActivate ? "text-green-600" : "text-red-600"
                  )} />
                ) : (
                  <Ban className={clsx(
                    "w-6 h-6",
                    isActivate ? "text-green-600" : "text-red-600"
                  )} />
                )}
              </div>
              <div className="flex-1">
                <h4 className="text-lg font-medium text-gray-900 mb-2">
                  Confirmar {isActivate ? 'Ativação' : 'Desativação'}
                </h4>
                <p className="text-gray-600">
                  Deseja {isActivate ? 'ativar' : 'desativar'} a permissão "{title}" para todos os usuários?
                </p>
              </div>
            </div>

            <div className="mt-6 flex justify-end gap-3">
              <button
                onClick={onClose}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
              >
                Cancelar
              </button>
              <button
                onClick={onConfirm}
                className={clsx(
                  "px-4 py-2 rounded-lg text-white transition-colors flex items-center gap-2",
                  isActivate 
                    ? "bg-green-600 hover:bg-green-700" 
                    : "bg-red-600 hover:bg-red-700"
                )}
              >
                {isActivate ? (
                  <Check className="w-4 h-4" />
                ) : (
                  <Ban className="w-4 h-4" />
                )}
                <span>Confirmar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};