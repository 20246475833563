import styled from "@emotion/styled"
import { useState } from "react";

interface Props {
    title: String,
    children?: React.ReactNode,
    toggleEnabled?: Boolean,
    isOpen?: Boolean
}

const FolderElement = styled.div`
    position: relative; 
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid var(--kt-input-border-color);
    border-radius: 0.475rem;
    
    .folder-header {
        position: relative; 
        
    }

    .folder-title {
        position: relative; 
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #eee;
        padding; 5px 10px;
        width: 100%;
        height: 40px;

        
    }

    .text-primary-color {
        color: var(--kt-primary);
    }

    .folder-content {
        position: relative; 
        padding: 15px;
        height: auto;
        overflow: visible;

        &.closed {
            height: 0;
            overflow: hidden;
            padding: 0;
        }
    }
`

const Folder:React.FC<Props> = (props) => {
    const [isOpen, setIsOpen] = useState(props.isOpen ? props.isOpen : false);

    const toggleOpen = (value:boolean) => {
        setIsOpen(value);
    };

    return (
    <FolderElement>
        <div className="folder-header">
            { props.toggleEnabled === false ? (
                <span className="folder-title text-primary-color">{ props.title }</span>
            ) : (
                <a href="#" className="folder-title" onClick={ e => {
                    e.preventDefault(); toggleOpen(!isOpen); 
                } }>{ props.title }</a>
            )}
        </div>
        <div className={`folder-content ${ isOpen ? '' : 'closed' }`}>
            { props.children }
        </div>
    </FolderElement>
    );
};

export default Folder;