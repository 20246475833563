import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import BtnClose from '../../components/BtnClose';
import * as API from '../../api';
import { messages } from '../utils/ErrorsText';
import { isSuperAdmin } from '../../modules/auth/core/Auth';
import { getUserData } from '../../modules/auth/core/Auth';
import IconAttachment from './../../components/IconAttachment';
import BtnView from './../../components/BtnStatusOpen';

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const StyledModal = styled.div`
    .card-header {
        align-items: center;
    }

    .button-view {
        position: relative;
        top: 8px;
        cursor: pointer;
    }

    .pdfViewer {
        object {
            width: 840px;
            height: 700px;
        }

        embed {
            width: 840px;
            height: 700px;
        }
    }
`;

interface Props {
    userData?: any,
    onClose: Function,
    hideClose?: boolean,
    path: string
}

const ViewerModal:FC<Props> = (props:Props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }:any) {
        setNumPages(numPages);
    }

    const getExtension = () => {
        const parts = props.path.split('.');
        return parts[parts.length - 1];
    }

    const isImage = () => {
        const extension = getExtension();
        return extension === 'jpg' || extension === 'jpeg' || extension === 'png';
    }

    const isPDF = () => {
        const extension = getExtension();
        return extension === 'pdf';
    }

    const previousPage = () => {
        setPageNumber(prevPageNumber => prevPageNumber - 1);
    }

    const nextPage = () => {
        setPageNumber(prevPageNumber => prevPageNumber + 1);
    }

    return (
    <StyledModal className="card mb-5">
        <div className='card-header border-0'>
            <div className='card-title m-0'>
                <h3>Manual</h3>
            </div>
            { props.hideClose ? <></> : <BtnClose onClick={() => props.onClose()} />}
        </div>
        
        <div className='card-body mb-5 mb-xl-10'>
            { isPDF() ? (  
            <div className='pdfViewer'>
                <iframe src={props.path} width="100%" height="600px"></iframe>
            </div>) : (
            <div>
                <img alt='Imagem' className='mh-500px mw-500px' src={props.path} />
            </div> )}
        </div>
    </StyledModal>
    )
}

export default ViewerModal