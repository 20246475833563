import { useState, useMemo, useEffect } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { getUserByToken, login } from '../core/_requests';
import { useAuth } from '../core/Auth';
import * as API from '../../../api';
import { sha256 } from 'crypto-hash';
import { AlertTriangle, Loader2 } from 'lucide-react';

const initialValues = {
  email: '',
  password: '',
  code: '',
};

const MAINTENANCE_CHECK_INTERVAL = 30000; // 30 seconds

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [stage, setStage] = useState('login');
  const [authData, setAuthData] = useState<any>(null);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [maintenanceRequestDone, setmaintenanceRequestDone] = useState(false);

  // Check maintenance status on mount and every 30 seconds
  useEffect(() => {
    checkMaintenanceStatus();
    const interval = setInterval(checkMaintenanceStatus, MAINTENANCE_CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const checkMaintenanceStatus = async () => {
    try {
      const isMaintenance = await API.isMaintenance();
      console.log(isMaintenance);
      setIsMaintenanceMode(isMaintenance);
    } catch (error) {
      setIsMaintenanceMode(true);
      console.error('Error checking maintenance status:', error);
    }
    setmaintenanceRequestDone(true);
  };

  const validationSchema = useMemo(() => {
    if (stage === 'login') {
      return Yup.object().shape({
        email: Yup.string()
          .email('Formato de e-mail incorreto')
          .min(6, 'Mínimo de 6 caracteres')
          .max(50, 'Máximo de 50 caracteres')
          .required('O Preenchimento do e-mail é necessário'),
        password: Yup.string()
          .min(6, 'Mínimo de 6 caracteres')
          .max(50, 'Máximo de 50 caracteres')
          .required('O Preenchimento da senha é necessário'),
      });
    } else if (stage === 'mfa') {
      return Yup.object().shape({
        code: Yup.string()
          .required('O código MFA é necessário')
          .length(6, 'O código MFA deve ter 6 dígitos'),
      });
    }
    return Yup.object().shape({});
  }, [stage]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (isMaintenanceMode) {
        setStatus('Sistema em manutenção. Tente novamente mais tarde.');
        setSubmitting(false);
        return;
      }

      setLoading(true);
      try {
        if (stage === 'login') {
          const password = await sha256(values.password);
          const auth = await API.login(values.email, password);

          if (!auth?.success) {
            if (auth.error === 'MAINTENANCE') {
              setStatus('Servidor em manutenção, tente novamente mais tarde');
              setIsMaintenanceMode(true);
            } else if (auth.error === 'PASSWORD_EXPIRED') {
              setStatus('Sua senha expirou! Você receberá um e-mail com um link para a criação de uma nova senha.');
            } else {
              setStatus('Dados de acesso incorretos');
            }
          } else {
            const authData = auth.data;
            if (authData.mfa_enabled && authData.has_mfa_secret) {
              setAuthData(authData);
              setStage('mfa');
            } else {
              const { data: user } = await getUserByToken(authData.api_token);
              saveAuth(authData);
              setCurrentUser(user);
            }
          }
        } else if (stage === 'mfa') {
          const response = await API.validateMFA({ code: values.code, token: authData.api_token });
          if (response.success) {
            const { data: user } = await getUserByToken(response.data);
            authData.api_token = response.data;
            saveAuth(authData);
            setCurrentUser(user);
          } else {
            setStatus('Código MFA inválido');
          }
        }
      } catch (error) {
        console.error('Login error:', error);
        saveAuth(undefined);
        setStatus('Dados de acesso incorretos');
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return !maintenanceRequestDone ? <>
    <div className=" p-4 bg-green-50 border border-green-200 rounded-xl">
          <div className="flex items-center gap-3 text-green-800">
            
            <div className="flex-1">
              <h2 className="text-sm font-semibold text-center">Carregando Sistema</h2>
            </div>
          </div>
        </div>
    </> : (
    
    <div>
      {isMaintenanceMode ? (
        <div className=" p-4 bg-yellow-50 border border-yellow-200 rounded-xl">
          <div className="flex items-center gap-3 text-yellow-800">
            <AlertTriangle className="w-5 h-5 flex-shrink-0" />
            <div className="flex-1">
              <h3 className="text-sm font-semibold">Sistema em Manutenção</h3>
              <p className="text-sm mt-1">
                O sistema está temporariamente indisponível para manutenção. 
                Voltaremos em alguns minutos.
              </p>
            </div>
          </div>
        </div>
      ) : <>

      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Insight Experience</h1>
        </div>

        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        {stage === 'login' && (
          <>
            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>E-mail</label>
              <input
                placeholder='E-mail'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>Senha</label>
                  {/* end::Label */}
                  {/* begin::Link */}
                  {
                    <Link
                      to='/auth/forgot-password'
                      className='link-primary fs-6 fw-bolder'
                      style={{marginLeft: '5px'}}
                    >
                      Esqueceu sua senha?
                    </Link>
                  }
                  {/* end::Link */}
                </div>
              </div>
              <input
                type='password'
                autoComplete='off'
                placeholder='Senha'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </>
        )}

        {stage === 'mfa' && (
          <>
            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>Código MFA</label>
              <input
                placeholder='Código MFA'
                {...formik.getFieldProps('code')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.code && formik.errors.code},
                  {
                    'is-valid': formik.touched.code && !formik.errors.code,
                  }
                )}
                type='text'
                name='code'
                autoComplete='off'
              />
              {formik.touched.code && formik.errors.code && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.code}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </>
        )}

        {/* begin::Action */}
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_in_submit"
            disabled={isMaintenanceMode || loading}
            className={clsx(
              "btn btn-lg w-100 mb-5",
              isMaintenanceMode 
                ? "btn-secondary cursor-not-allowed opacity-50"
                : "btn-primary"
            )}
          >
            {!loading && (
              <span className="indicator-label">
                {stage === 'login' ? 'Acessar' : 'Validar MFA'}
              </span>
            )}
            {loading && (
              <span className="indicator-progress flex items-center justify-center gap-2">
                <Loader2 className="w-5 h-5 animate-spin" />
                <span>Aguarde por favor...</span>
              </span>
            )}
          </button>
        </div>
      </form>
      </> }
    </div>
  );
}