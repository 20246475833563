import React from 'react';
import { isSuperAdmin } from '../../../modules/auth/core/Auth';
import clsx from 'clsx';

type UserFormProps = {
  name: string;
  email: string;
  isAdmin: number;
  isActived: number;
  isMailling: number;
  isViewer: number;
  domain: string;
  validateEmail: () => string;
  validateName: () => string;
  onNameChange: (value: string) => void;
  onEmailChange: (value: string) => void;
  onAdminChange: (value: number) => void;
  onActivedChange: (value: number) => void;
  onMaillingChange: (value: number) => void;
  onViewerChange: (value: number) => void;
};

export const UserForm = ({
  name,
  email,
  isAdmin,
  isActived,
  isMailling,
  isViewer,
  domain,
  validateEmail,
  validateName,
  onNameChange,
  onEmailChange,
  onAdminChange,
  onActivedChange,
  onMaillingChange,
  onViewerChange,
}: UserFormProps) => (
  <div className="space-y-6">
    {domain && (
      <div className="text-sm text-gray-600 bg-blue-50 p-3 rounded-lg">
        Será permitido apenas e-mails relacionados ao domínio <strong>{domain}</strong>
      </div>
    )}

    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">E-mail</label>
        <input
          type="email"
          value={email}
          onChange={(e) => onEmailChange(e.target.value)}
          className={clsx(
            "w-full px-4 py-2 rounded-lg border focus:ring-2",
            validateEmail() === 'is-valid' 
              ? "border-green-300 focus:ring-green-200"
              : validateEmail() === 'is-invalid'
              ? "border-red-300 focus:ring-red-200"
              : "border-gray-200 focus:ring-blue-200"
          )}
          placeholder="email@exemplo.com"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Nome</label>
        <input
          type="text"
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
          className={clsx(
            "w-full px-4 py-2 rounded-lg border focus:ring-2",
            validateName() === 'is-invalid'
              ? "border-red-300 focus:ring-red-200"
              : "border-gray-200 focus:ring-blue-200"
          )}
          placeholder="Nome completo"
        />
      </div>

      {isSuperAdmin() && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Nível de Acesso</label>
          <select
            value={isAdmin}
            onChange={(e) => onAdminChange(Number(e.target.value))}
            className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-200"
          >
            <option value={0}>Usuário</option>
            <option value={1}>Administrador</option>
          </select>
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">Status</label>
        <div className="flex gap-6">
          <label className="inline-flex items-center">
            <input
              type="radio"
              checked={isActived === 1}
              onChange={() => onActivedChange(1)}
              className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
            />
            <span className="ml-2 text-sm text-gray-700">Ativo</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              checked={isActived === 0}
              onChange={() => onActivedChange(0)}
              className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
            />
            <span className="ml-2 text-sm text-gray-700">Inativo</span>
          </label>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">Mailing</label>
        <div className="flex gap-6">
          <label className="inline-flex items-center">
            <input
              type="radio"
              checked={isMailling === 1}
              onChange={() => onMaillingChange(1)}
              className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
            />
            <span className="ml-2 text-sm text-gray-700">Sim</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              checked={isMailling === 0}
              onChange={() => onMaillingChange(0)}
              className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
            />
            <span className="ml-2 text-sm text-gray-700">Não</span>
          </label>
        </div>
      </div>

      {isSuperAdmin() && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Visualizador</label>
          <div className="flex gap-6">
            <label className="inline-flex items-center">
              <input
                type="radio"
                checked={isViewer === 1}
                onChange={() => onViewerChange(1)}
                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-700">Sim</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                checked={isViewer === 0}
                onChange={() => onViewerChange(0)}
                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-700">Não</span>
            </label>
          </div>
        </div>
      )}
    </div>
  </div>
);