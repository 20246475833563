import styled from '@emotion/styled';
import React, { FC, useEffect, useState } from 'react';
import IChart from './IChart';
import * as API from '../../../app/api';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface Props {
    edit:boolean,
    data:IChart,
    home:boolean
}

const StyledChart = styled.div<Props>`
    background-color: white;
    width: 100%;
    height: 100%;

    border: 1px solid #ddd;
    padding: 1rem;

    .content {
        opacity: ${props => props.edit && !props.home ? 0.5 : 1};
        pointer-events: ${props => props.edit && !props.home ? 'none' : 'unset'};
        width: 100%;
        height: 100%;
    }

    &:hover {
        .content {
            opacity: 1;
        }
    }
`

const Chart:FC<Props> = (props:Props) => {

    const type:string = props.data.chart;
    const [data, setData] = useState<any>(null);

    useEffect(() => {

        const propData:any = props.data.data;
        const keys = Object.keys(propData);
        const labels:string[] = propData[keys[0]];
        const values:number[] = propData[keys[1]];

        let chartData = {
            labels,
            datasets: [ {
                data: values,
                backgroundColor: [
                    'rgba(255, 99, 132,  0.7)',
                    'rgba(255, 159, 64,  0.7)',
                    'rgba(255, 205, 86,  0.7)',
                    'rgba(75, 192, 192,  0.7)',
                    'rgba(54, 162, 235,  0.7)',
                    'rgba(153, 102, 255, 0.7)',
                    'rgba(201, 203, 207, 0.7)'
                ],
            }],
        };

        setData(chartData);
    }, []);

    function drawChart() {
        if (!data) return <></>

        let options:any = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
                position: 'bottom' as const,
              },
              title: {
                display: (props.data.title && props.data.title.trim().length > 0) ? true : false,
                text: props.data.title,
              },
            },
        };


        if (type === 'pie') {
            options.plugins.legend.display = true;
            return <Pie options={options} data={data}/>
        }
        else if (type === 'hbar') {
            options.indexAxis = 'y'
            return <Bar options={options} data={{...data, axis:'y'}} />
        }
        else if (type === 'bar') {
            return <Bar options={options} data={data} />
        }
    }

    function onClickChart() {
        console.log(props)
    }
    
    return <StyledChart {...props}>
        <div className="content" onClick={onClickChart}>
            { drawChart() }
        </div>
    </StyledChart>
}

export default Chart;

