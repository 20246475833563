import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import * as API from '../../../api';
import { CompanyFilter } from './CompanyFilter';
import { CompanySelect } from './CompanySelect';
import { FeatureTable } from './FeatureTable';
import { ConfirmModal } from '../../users/FeatureMatrix/ConfirmModal';

export const CompanyFeatureMatrix = () => {
  const [menu, setMenu] = useState<any>();
  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);
  const [companiesData, setCompaniesData] = useState<any[]>([]);
  const [selectedFilter, setSelectedFilter] = useState('Todos');
  const [confirmModal, setConfirmModal] = useState<any>({
    isOpen: false,
    feature: null,
    action: 'activate' as 'activate' | 'deactivate'
  });
  const [updateIndex, setUpdateIndex] = useState<number>(0);

  useEffect(() => {
    getFeatures();
  }, []);

  useEffect(() => {
    if (selectedCompanies.length) {
      setCompaniesData(selectedCompanies);
    } else {
      if (menu) setCompaniesData(menu.companys);
    }
  }, [selectedCompanies, menu?.companys]);

  const getFeatures = async () => {
    const result = await API.getFeatures();
    setMenu(result.data);
    
    const formattedCompanies = result.data.companys.map((item: any) => ({
      value: item.guid,
      label: item.name,
      ...item
    }));
    setCompanies(formattedCompanies);
  };

  const handleFeatureClick = (feature: any) => {
    // Check if all companies have this permission
    const allChecked = companiesData.every(company => 
      menu.permissions.some((p: any) => 
        p.id_feature === feature.id_feature && p.id_company === company.id_company
      )
    );

    setConfirmModal({
      isOpen: true,
      feature,
      action: allChecked ? 'deactivate' : 'activate'
    });
  };

  const handleConfirmFeature = async () => {
    const { feature, action } = confirmModal;
    
    // Update permissions for all companies
    for (const company of companiesData) {
      if (action === 'activate') {
        await API.addFeatureCompanyPermission({
          id_feature: feature.id_feature,
          id_company: company.id_company
        });
      } else {
        await API.removeFeatureCompanyPermission({
          id_feature: feature.id_feature,
          id_company: company.id_company
        });
      }
    }

    // Refresh data
    await getFeatures();
    setUpdateIndex(updateIndex + 1)
    setConfirmModal({ isOpen: false, feature: null, action: 'activate' });
  };

  const handlePermissionChange = async (featureId: number, companyId: number, checked: boolean) => {
    if (checked) {
      await API.addFeatureCompanyPermission({ id_feature: featureId, id_company: companyId });
      menu.permissions.push({ guid: Math.random(), id_company: companyId, id_feature: featureId });
    } else {
      await API.removeFeatureCompanyPermission({ id_feature: featureId, id_company: companyId });
      const index = menu.permissions.findIndex((p: any) => 
        p.id_feature === featureId && p.id_company === companyId
      );
      if (index !== -1) {
        menu.permissions.splice(index, 1);
      }
    }
    setMenu({ ...menu });
  };

  const filteredCompanies = companiesData.filter(company => {
    return selectedFilter === 'Todos' || company.id_type_company.toString() === selectedFilter;
  });

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <PageTitle>Funcionalidades</PageTitle>

      <div className="space-y-6">
        <CompanyFilter
          selectedFilter={selectedFilter}
          onFilterChange={setSelectedFilter}
        />

        <CompanySelect
          companies={companies}
          selectedCompanies={selectedCompanies}
          onChange={setSelectedCompanies}
        />

        {menu?.features?.length ? (
          <FeatureTable
            features={menu.features}
            companies={filteredCompanies}
            permissions={menu.permissions}
            onFeatureClick={handleFeatureClick}
            onPermissionChange={handlePermissionChange}
            index={updateIndex}
          />
        ) : (
          <div className="text-center py-12 text-gray-500">
            Nenhuma funcionalidade encontrada
          </div>
        )}

        <ConfirmModal
          isOpen={confirmModal.isOpen}
          onClose={() => setConfirmModal({ ...confirmModal, isOpen: false })}
          onConfirm={handleConfirmFeature}
          title={confirmModal.feature?.title || ''}
          action={confirmModal.action}
        />
      </div>
    </div>
  );
};