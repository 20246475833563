import React from 'react';
import {
  MessageSquare,
  Hash,
  Smile,
  Star,
  ThumbsUp,
  CheckSquare,
  Heart,
  Sliders,
  List,
  BarChart,
} from 'lucide-react';

interface QuestionTypeOption {
  value: string;
  label: string;
  icon: React.ElementType;
  description: string;
}

const questionTypes: QuestionTypeOption[] = [
  { value: 'text', label: 'Texto', icon: MessageSquare, description: 'Feedback detalhado' },
  { value: 'number', label: 'Numérica', icon: Hash, description: 'Escala 1-10' },
  { value: 'emoticon', label: 'Emoticons', icon: Smile, description: 'Nível de satisfação' },
  { value: 'stars', label: 'Estrelas', icon: Star, description: 'Avaliação 5 estrelas' },
  { value: 'thumbs', label: 'Polegar', icon: ThumbsUp, description: 'Positivo/Negativo' },
  { value: 'yesno', label: 'Sim/Não', icon: CheckSquare, description: 'Resposta binária' },
  { value: 'hearts', label: 'Corações', icon: Heart, description: 'Escala afetiva' },
  { value: 'slider', label: 'Slider', icon: Sliders, description: 'Porcentagem' },
  { value: 'choice', label: 'Escolhas', icon: List, description: 'Opções customizadas' },
  { value: 'rating', label: 'Rating', icon: BarChart, description: 'Níveis qualitativos' },
];

interface QuestionTypeSelectProps {
  value: string;
  onChange: (value: string) => void;
}

export default function QuestionTypeSelect({ value, onChange }: QuestionTypeSelectProps) {
  const buttonStyle = {
    transition: 'all 0.2s',
    padding: '10px',
    borderRadius: '8px',
    border: '1px solid #E5E7EB', // border-gray-100
    backgroundColor: '#FFFFFF', // bg-white
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    position: 'relative' as const,
    overflow: 'hidden',
  };

  const selectedButtonStyle = {
    backgroundColor: '#EFF6FF', // bg-blue-50
    boxShadow: '0 0 0 2px rgba(59, 130, 246, 0.5)', // ring-2 ring-blue-200 ring-opacity-50
  };

  const iconContainerStyle = {
    width: '32px',
    height: '32px',
    borderRadius: '9999px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '6px',
    transition: 'background-color 0.2s',
    backgroundColor: '#F9FAFB', // bg-gray-50
  };

  const selectedIconContainerStyle = {
    backgroundColor: '#DBEAFE', // bg-blue-100
  };

  const labelStyle = {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '1',
    color: '#4B5563', // text-gray-600
    transition: 'color 0.2s',
  };

  const selectedLabelStyle = {
    color: '#2563EB', // text-blue-600
  };

  const descriptionOverlayStyle = {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.95)', // bg-white with opacity
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity 0.2s',
    pointerEvents: 'none' as const,
  };

  const descriptionTextStyle = {
    fontSize: '10px',
    fontWeight: 500,
    color: '#6B7280', // text-gray-500
    padding: '0 4px',
    textAlign: 'center' as const,
  };

  // Styles for the container grid
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
    gap: '8px',
  };

  return (
    <div style={gridStyle}>
      {questionTypes.map((type) => {
        const Icon = type.icon;
        const isSelected = value === type.value;

        return (
          <button
            key={type.value}
            onClick={() => onChange(type.value)}
            style={{
              ...buttonStyle,
              ...(isSelected ? selectedButtonStyle : {}),
            }}
            onMouseEnter={(e) => {
              const button = e.currentTarget;
              button.style.transform = 'translateY(-2px)';
              button.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.05)'; // hover:shadow-sm
              const overlay = button.querySelector('.description-overlay') as HTMLElement;
              if (overlay) {
                overlay.style.opacity = '1';
              }
            }}
            onMouseLeave={(e) => {
              const button = e.currentTarget;
              button.style.transform = 'translateY(0)';
              button.style.boxShadow = 'none';
              const overlay = button.querySelector('.description-overlay') as HTMLElement;
              if (overlay) {
                overlay.style.opacity = '0';
              }
            }}
          >
            <div
              style={{
                ...iconContainerStyle,
                ...(isSelected ? selectedIconContainerStyle : {}),
              }}
            >
              <Icon
                style={{
                  width: '16px',
                  height: '16px',
                  color: isSelected ? '#2563EB' : '#6B7280', // text-blue-600 or text-gray-500
                  transition: 'color 0.2s',
                }}
              />
            </div>

            <span
              style={{
                ...labelStyle,
                ...(isSelected ? selectedLabelStyle : {}),
              }}
            >
              {type.label}
            </span>

            <div
              className="description-overlay"
              style={{
                ...descriptionOverlayStyle,
              }}
            >
              <span style={descriptionTextStyle}>{type.description}</span>
            </div>
          </button>
        );
      })}
    </div>
  );
}